import * as THREE from 'three';
import {Lensflare, LensflareElement} from 'three/examples/jsm/objects/Lensflare.js';

import {readState, subscribeToState} from '../../lib/index.js';

import {scene} from '../Scene.js';

import {getDate} from '../../Utils/ApplicationDate.js';
import getEarthSeasonalInclination from '../../Utils/getEarthSeasonalInclination.js';

import flare0 from '../../../img/lensflare/lensflare0.png';
//import flare2 from '../../../img/lensflare/lensflare2.png';
//import flare3 from '../../../img/lensflare/lensflare3.png';

/////////////
// Parameters
//const flareDistance = 240000;
const flareDistance = 100000;

/////////////////
// Actual Objects
let lensFlare;
let eulerLight;
let secondAngle;

/////////////////////
// Dynamic properties
let coordinateSystem = null;
const setCoordinateSystem = (oldValue, newValue) => {
    coordinateSystem = newValue;
    rotateSun();
}

const initSun = function() {
    eulerLight = new THREE.Euler(0, 0, 0);
    setCoordinateSystem(null, readState('coordinateSystem'));

    subscribeToState('coordinateSystem', setCoordinateSystem);
    subscribeToState('timeProjection.value', () => rotateSun(false));

    const textureFlare0 = new THREE.TextureLoader().load(flare0);
    //const textureFlare2 = new THREE.TextureLoader().load(flare2);
    //const textureFlare3 = new THREE.TextureLoader().load(flare3);

    const flareColor = new THREE.Color(0xffffff);

    flareColor.setHSL(0.55, 0.9, 0.5 + 0.5);

    lensFlare = new Lensflare();

    lensFlare.addElement( new LensflareElement(textureFlare0, 700, 0.0, flareColor));

    //lensFlare.addElement( new LensflareElement(textureFlare2, 512, 0.0));

    //lensFlare.addElement( new LensflareElement(textureFlare3, 60, 0.6));
    //lensFlare.addElement( new LensflareElement(textureFlare3, 70, 0.7));
    //lensFlare.addElement( new LensflareElement(textureFlare3, 120, 0.9));
    //lensFlare.addElement( new LensflareElement(textureFlare3, 70, 1.0));

    lensFlare.position.set(0, 0, flareDistance);

    secondAngle = ((Math.PI / 12) / 60) / 60;

    scene.add(lensFlare);

    rotateSun();
}

const rotateSun = function(createTimeout = true){
    if (lensFlare == undefined) {
        return;
    }
    if (coordinateSystem == 'eci') {
        lensFlare.position.set(0, 0, flareDistance);
        return;
    }
    //Rotating the sun to expose the right side on the earth
    const now            = getDate();
    const nowGMT         = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);
    const currentMinutes = nowGMT.getMinutes();
    const currentHours   = nowGMT.getHours();
    const currentSeconds = nowGMT.getSeconds();

    //Shader light position
    const totalSeconds = currentSeconds + (currentMinutes * 60) + ((currentHours * 60) * 60);
    const angle        = Math.PI - (secondAngle * totalSeconds);
    eulerLight         = new THREE.Euler(0, angle, 0);

    //Lensflare position
    const angleDegree = angle + (Math.PI / 2);// * (180/Math.PI);
    const z           = flareDistance * Math.sin(angleDegree);
    const x           = flareDistance * Math.cos(angleDegree);
    const y           = flareDistance * Math.sin(getEarthSeasonalInclination() * 2 * Math.PI / 360);
    lensFlare.position.set(z, y, x);

    if (createTimeout) {
        setTimeout(rotateSun, 30 * 1000);
    }
}

const killSun = function() {
    lensFlare   = null;
    eulerLight  = null;
    secondAngle = null;
}

export {initSun, eulerLight, killSun};
