import * as THREE from 'three';

// Rotate an object around an arbitrary axis in world space
const rotateAroundWorldAxis = function (object, axis, radians) {
    const rotWorldMatrix = new THREE.Matrix4();
    rotWorldMatrix.makeRotationAxis(axis.normalize(), radians);

    rotWorldMatrix.multiply(object.matrix);                // pre-multiply

    object.matrix = rotWorldMatrix;

    object.rotation.setFromRotationMatrix(object.matrix);
}

const rotateAroundObjectAxis = function(object, axis, radians) {
    const rotObjectMatrix = new THREE.Matrix4();
    rotObjectMatrix.makeRotationAxis(axis.normalize(), radians);

    object.matrix.multiply(rotObjectMatrix);

    object.rotation.setFromRotationMatrix(object.matrix);
}


export {rotateAroundWorldAxis, rotateAroundObjectAxis};
