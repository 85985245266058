import {Component, html, render} from '../lib/index.js';


class OpenMenuButton extends HTMLElement {
    constructor(params) {
        super()

        this.update();
    }

    template() {
        return html`<div id="inner"></div>`;
    }

    update() {
        render(this, this.template());
    }
}

customElements.define("open-menu-button", OpenMenuButton);

export default OpenMenuButton;
