import {classes, Component, html, render} from '../lib/index.js';

import FPVCompass from './FPVCompass.js';
import FPVGlobalCross from './FPVGlobalCross.js';
import FPVVerticalScale from './FPVVerticalScale.js';

class FPVUI extends Component {
    constructor(params) {
        super()
    }

    static get mappedProperties() {
        return [
            'focusedObject',
            'cameraAngles.vertical',
            'focalFactor',
        ]
    }

    renderComponent() {

        const scale  = Math.round(this.getState('focalFactor') * 100) / 100;
        let rescaled = (scale - 0.4) / (3 - 0.4);
        rescaled     = Math.round(rescaled  * 100) / 100

        const scaledFocalFactor = (rescaled * 2) - 1;

        render(this, html`
            <fpv-compass />
            <fpv-global-cross id="globalCross" class=${classes({'focusedSat': this.getState('focusedObject') != null})} />
            <fpv-vertical-scale
                id="zoomScale"
                class="verticalScale left"
                data-label-precision="2"
                data-max="1"
                data-min="-1"
                data-value="${scaledFocalFactor}" />

            <fpv-vertical-scale
                id="heightScale"
                class="verticalScale"
                data-label-precision="0"
                data-max="90"
                data-min="-90"
                data-value="${this.getState('cameraAngles.vertical')}" />
        `);
    }
}

customElements.define("fpv-ui", FPVUI);

export default FPVUI;
