import {Actions, classes, Component, dispatch, html, render, subscribeToState} from '../lib/index.js';

import AutoCompleteTLEsResult from './AutoCompleteTLEsResult.js';

import {constellations} from '../Utils/Constellations.js';
import {importFromUrl} from '../Utils/TLEImport.js';

class AutoCompleteTLEs extends Component{
    #cachedSatList;
    constructor() {
        super();
    }

    static get initialState() {
        return {
            searchString: "",
            showResult: false,
            results: [],
            resultsConstellation: []
        }
    };

    static get mappedProperties() {
        return [
            'TLE.available',
            'TLE.activated',
            'TLE.constellations',
        ]
    }

    updateCachedList() {
        this.#cachedSatList = [];
        this.getState('TLE.activated').map(TLE => this.#cachedSatList.push(TLE[3]));
        this.getState('TLE.constellations').map(constellation => {
            constellation.TLEs.map(TLE => this.#cachedSatList.push(TLE[3]));
        });
    }

    onInput(e) {
        this.setState({'searchString': e.currentTarget.value});

        this.search();
    }

    search() {
        this.updateCachedList();
        const string = this.getState('searchString');
        let results  = [];
        let resultsConstellation = [];
        if (string != "") {
            var reg = new RegExp(string, 'i');
            results = this.getState('TLE.available')
                .filter((sat) => sat[0].match(reg))
                .filter((sat) => !this.isSelected(sat[3]))
            ;

            resultsConstellation = Object.keys(constellations)
            .filter(constellation => constellation.match(reg))
            .filter(constellation => !this.isConstellationSelected(constellation));
        }

        this.setState({
            results: results,
            resultsConstellation: resultsConstellation,
            showResult: results.length > 0 || resultsConstellation.length > 0
        });
    }

    isSelected(searchId) {
        const selected = this.#cachedSatList.filter(satId => satId === searchId );

        return selected.length > 0;
    }

    isConstellationSelected(name) {
        const selected = this.getState('TLE.constellations').filter(constellation => constellation.name == name );

        return selected.length > 0;
    }


    selectResult(satellite) {
        dispatch(Actions.TLEAddActivated, [satellite]);
        this.search();
    }

    selectConstellation(name) {
        importFromUrl(constellations[name].url, (TLEs => {
            dispatch(Actions.TLEAddConstellation, {TLEs: TLEs, name: name});
            this.search();
        }));
    }

    renderComponent() {

        render(this, html`
            <input
                type="text"
                id="search"
                .disabled=${this.getState('TLE.available') == false}
                name="name"
                autocomplete="off"
                onkeyup="${e => this.onInput(e)}"
                placeholder=${this.getState('TLE.available') == false ? 'Loading' : 'Search TLEs'}
            />
            <div id="results" class=${classes({show: this.getState('showResult')})}>
                ${this.getState('resultsConstellation').slice(0, 2).map(result => {
                    return html.for(this, result)`<autocomplete-tles-result tle-name=${result} tle-constellation="1" onclick=${e => this.selectConstellation(result)}></autocomplete-tles-result>`}
                )}
                ${this.getState('results').slice(0, 10).map(result => {
                    return html.for(this, result[3])`<autocomplete-tles-result tle-name=${result[0]} onclick=${e => this.selectResult(result)}></autocomplete-tles-result>`}
                )}
            </div>
        `);
    }
}

customElements.define("autocomplete-tles", AutoCompleteTLEs);

export default AutoCompleteTLEs;
