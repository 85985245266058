import {Component, html, render} from '../lib/index.js';

class FPVGlobalCross extends Component {
    constructor(params) {
        super()
    }

    renderComponent() {
        render(this, html`
        <div id="v"></div>
        <div id="h"></div>
            <div id="globalCross-higlight">
                    <div id="centerCross">
                <div id="cch"></div>
                <div id="ccv"></div>
            </div>
            <div id="leftArrow"></div>
            <div id="rightArrow"></div>

            <div id="redLeftArrow"></div>
            <div id="redRightArrow"></div>

            <div id="topArrow"></div>
            <div id="bottomArrow"></div>

            <div id="horizontal">
                <div id="gh-left"></div>
                <div id="gh-right"></div>
            </div>
        </div>
        `);
    }
}

customElements.define("fpv-global-cross", FPVGlobalCross);

export default FPVGlobalCross;
