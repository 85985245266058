import * as THREE from 'three';

import {readState} from '../lib/index.js';

import {scene} from './Scene.js';
import {getCamera} from './Cameras/AppCamera.js';
import {controls} from './Controls/AppControls.js';

import {checkIntersection} from '../Utils/Intersection.js';
import {computeDate} from '../Utils/ApplicationDate.js';

import {updateEarthForRender} from './SceneElements/Earth.js'
import {satellitesCollection} from './SceneElements/SatellitesCollection.js';

//DEBUG
import {updateDebugForRender} from './debug.js';

let renderer = undefined;
let renderWidth;
let renderHeight;
let renderIsActive = true;

const getRenderer = function(width, height) {

    if (renderer == undefined) {
        renderIsActive = true;
        renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
        renderer.setSize(width, height);
        renderer.setClearColor(0x000000, 1);
        renderer.setPixelRatio(window.devicePixelRatio);

        renderWidth  = width;
        renderHeight = height;
    }

    return renderer;
}
//setTimeout(function() {
    //console.log(renderer.info);
//}, 5000);

const renderApp = function()
{
    computeDate();

    if (renderIsActive === false) {
        return;
    }
    controls.update();

    Object.values(satellitesCollection).map(sat => sat.updateForRender());

    updateEarthForRender();
    //app.moon.updateForRender();

    checkIntersection();
    renderer.render(scene, getCamera().camera);

    if (process.env.ENV == "dev") {
        updateDebugForRender();
    }

    window.requestAnimationFrame(renderApp);
}

const stopRenderApp = () => {
    renderIsActive = false;
    renderer = undefined;
}

export {getRenderer, renderApp, renderWidth, renderHeight, stopRenderApp};
