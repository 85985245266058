import {readState, subscribeToState} from '../../lib/index.js';

import Orbital from './Orbital.js';
import SatCam  from './SatCam.js';
import FPV     from './FPV.js';

let sceneDomElement = null;
let controls = null;

let target = null;

let previousViewMode = null;

const changeControls = function() {
    const currentViewMode = readState('viewMode');

    if (currentViewMode == previousViewMode && controls) {
        return;
    }

    if (controls) {
        controls.stop();
    }

    switch (currentViewMode) {
        case 'orbital':
            controls = new Orbital(sceneDomElement);
        break;
        case 'fpv':
            controls = new FPV(sceneDomElement);
        break;
        case 'satCam':
            controls = new SatCam(sceneDomElement);
        break;
    }

    previousViewMode = currentViewMode;
}
subscribeToState('viewMode', changeControls);


const initControls = function({element}) {
    sceneDomElement = element;
    changeControls();
}

const killControls = function() {
    controls.stop();
    controls = null;
    target   = null;
}

export {
    initControls,
    controls,
    killControls
};
