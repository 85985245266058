import {Component, html, render} from '../lib/index.js';

class FPVVerticalScale extends Component {
    constructor(params) {
        super()
    }

    static get observedAttributes() {
        return ["data-value"];
    }

    connectedCallback() {
        this.labelPrecision = this.getAttribute('data-label-precision');
        this.max            = this.getAttribute('data-max');
        this.min            = this.getAttribute('data-min');
        this.value          = this.getAttribute('data-value');

        super.connectedCallback();

        this.setGraduation();
    }

    setGraduation() {
        this.rootHeight = this.offsetHeight;

        const unitHeight = 40;

        this.steps      = Math.floor(this.rootHeight / unitHeight);
        this.diff       = this.rootHeight - (unitHeight * this.steps);

        let labelSteps  = (Math.abs(this.max) + Math.abs(this.min)) / this.steps;

        let items = [];
        //let tpl = '';
        for (let i = 0; i <= this.steps; i++) {
            let label = this.max - (labelSteps * i);
            let exp = Math.pow(10, this.labelPrecision);
            label   = Math.round(label * exp) / exp;

            label   = label > this.max ? this.max : label;
            label   = label < this.min ? this.min : label;

            items.push({
                label: label,
                last: i == this.steps
            });
            //tpl += this.unitTemplate(this.max - (labelSteps * i), i == this.steps);
        }

        render(this.querySelector('.vGraduation'), html`
            ${items.map(data => {
                if (!data.last) {
                    return html`<div class="b">
                            <div class="b-label">${data.label}</div>
                        </div>
                        <div class="s"></div>
                        <div class="s"></div>
                        <div class="s"></div>`;
                } else {
                    return html`<div class="b">
                            <div class="b-label">${data.label}</div>
                        </div>`
                }
            })}
        `);

        this.style.marginTop = `${this.diff / 2}px`;
    }

    unitTemplate(label, last) {
        let exp = Math.pow(10, this.labelPrecision);
        label   = Math.round(label * exp) / exp;

        label   = label > this.max ? this.max : label;
        label   = label < this.min ? this.min : label;

        let tpl = `<div class="b"><div class="b-label">${label}</div></div>`;
        if (!last) {
            tpl += `<div class="s"></div><div class="s"></div><div class="s"></div>`;
        }


    };

    renderComponent() {

        const exp   = Math.pow(10, this.labelPrecision);
        const value = Math.round(this.getAttribute('data-value') * exp) / exp;

        let top = (value / Math.abs(this.max) + 1) / 2;
        top     = Math.round( (1 - top) * 100 );

        render(this, html`
            <div class="vLine"></div>
            <div class="vLabel">
                <div class="vValue" style=${`top: ${top}%`}}>
                    <div class="vValue_inner">${value}</div>
                </div>
            </div>
            <div class="vGraduation"></div>
        `);
    }
}

customElements.define("fpv-vertical-scale", FPVVerticalScale);

export default FPVVerticalScale;
