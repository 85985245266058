import Camera from './Camera.js';
import {earthRadius} from '../Settings.js';

class OrbitalCamera extends Camera {

    static mode = 'orbital';

    constructor(params) {
        super(params);

        this.camera.position.set(0, 0, earthRadius * 3);
    }

    getFocalFactorLimit() {
        return {
            min: 0.4,
            max: 3
        };
    }
}

export default OrbitalCamera;
