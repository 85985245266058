import {readState, subscribeToState} from '../../lib/index.js';

import FPVCamera from './FPVCamera.js';
import OrbitalCamera from './OrbitalCamera.js';

// Enum of available cameras
let cameraFromMode = null

// Keeping viewMode locally to avoid requesting state on each threejs frame
let currentViewMode;

const initCameras = function({appWidth, appHeight}) {
    cameraFromMode = {};
    cameraFromMode[OrbitalCamera.mode] = new OrbitalCamera({appWidth, appHeight});
    cameraFromMode[FPVCamera.mode]     = new FPVCamera({appWidth, appHeight});
    cameraFromMode['satCam']           = new FPVCamera({appWidth, appHeight});

    currentViewMode = readState('viewMode');
}

const setCurrentMode = () => {
    currentViewMode = readState('viewMode');
}
// Subscribe currentViewMode to global state
subscribeToState('viewMode', setCurrentMode);

const getCamera = function() {
    return cameraFromMode[currentViewMode];
}

//
const getCameraDirection = function () {
    return getCamera().getCameraDirection();
}

const setFocalFactor = function(factor) {
    return getCamera().setFocalFactor(factor);
}
subscribeToState('setFocalFactor', setFocalFactor);

const killCameras = function() {
    cameraFromMode = null;
    currentViewMode = null;
}


export {
    initCameras,
    getCamera,
    getCameraDirection,
    setFocalFactor,
    killCameras
}
