import * as THREE from 'three';

import {Actions, dispatch, readState, subscribeToState} from '../lib/index.js';

import {getCamera} from '../App/Cameras/AppCamera.js';
import {satellites} from '../App/Scene.js';

import {getSatelliteIdFromInstanceId} from '../App/SceneElements/SatelliteMeshes.js';
import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

const screenPosition  = new THREE.Vector2();
const threejsPosition = new THREE.Vector2(200, 200);
const raycaster       = new THREE.Raycaster();
let DOMElement;

let INTERSECTED       = null;

let appWidth;
let appHeight;

const initIntersection = function(element, width, height) {
    DOMElement = element;
    DOMElement.addEventListener('mousemove', move);
    DOMElement.addEventListener('touchstart', touch);

    appWidth  = width;
    appHeight = height;

    raycaster.linePrecision = 0;
}

const touch = function(e) {
    e.preventDefault();
    if (e.targetTouches[0]) {
        threejsPosition.x  = (e.targetTouches[0].pageX / appWidth) * 2 - 1;
        threejsPosition.y  = - (e.targetTouches[0].pageY / appHeight) * 2 + 1;
        screenPosition.x   = e.targetTouches[0].pageX;
        screenPosition.y   = e.targetTouches[0].pageY;
    }
}

const startFpv = function() {
    DOMElement.removeEventListener('mousemove', move);
    DOMElement.removeEventListener('touchstart', touch);

    threejsPosition.x  = 0;
    threejsPosition.y  = 0;
    screenPosition.x   = appWidth / 2;
    screenPosition.y   = appHeight / 2;
}

const stopFpv = function() {
    DOMElement.addEventListener('mousemove', move);
    DOMElement.addEventListener('touchstart', touch);
}

const move = function(e) {
   //this where begin to transform the mouse cordinates to three,js cordinates
    e.preventDefault();
    threejsPosition.x = (e.clientX / appWidth) * 2 - 1;
    threejsPosition.y = - (e.clientY / appHeight) * 2 + 1;
    screenPosition.x  = e.clientX;
    screenPosition.y  = e.clientY;
}

const checkIntersection = function() {
    raycaster.setFromCamera(threejsPosition, getCamera().camera);

    //asking the raycaster if the mouse click touched the sphere object
    var intersects = raycaster.intersectObjects(
        satellites.children
    );

    //the ray will return an array with length of 1 or greater if the pointer
    //does touch the sphere object
    if (intersects.length > 0) {

        if (INTERSECTED != intersects[0].instanceId) {

            if (INTERSECTED) {
                const oldSat  = selectSatellite(INTERSECTED);
                oldSat.out(screenPosition);
            } else {
                // New intersect
            }

            INTERSECTED = intersects[0].instanceId;
            const sat   = selectSatellite(INTERSECTED);
            sat.over(screenPosition);

            dispatch(Actions.setHoverObject, sat.id);
        }
    } else {
        if (INTERSECTED !== null) {
            const oldSat  = selectSatellite(INTERSECTED);
            oldSat.out(screenPosition);
            INTERSECTED = null;
            //document.querySelector('#globalCross').classList.remove('hoverSat');
            dispatch(Actions.setHoverObject, null);
        }
    }
}

const selectSatellite = function(instanceId) {
    const satId     = getSatelliteIdFromInstanceId(instanceId);

    return satellitesCollection[satId];
}

const setViewMode = (oldValue, newValue) => {
    if (newValue == 'fpv') {
        startFpv();
    }
    if (oldValue == 'fpv' && newValue !=='fpv') {
        stopFpv();
    }
}

subscribeToState('viewMode', setViewMode);


export {checkIntersection, initIntersection}
