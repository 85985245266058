import {Router, View, html, render} from './lib/index.js';

import state from './state.js';
import PageApp from './Page/PageApp.js';
import PageAbout from './Page/PageAbout.js';

addEventListener('DOMContentLoaded', () => {

    const routes = [
        {
            name: 'app',
            url: '',
            page: PageApp
        },
        {
            name: 'about',
            url: 'about/',
            page: PageAbout
        }
    ];

    Router.initRouter(routes, {
        baseUrl: process.env.BASE_URL
    });

    let myView = Router.getView();

    document.body.append(myView);
}, {once: true});
