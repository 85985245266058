import {Actions, classes, Component, dispatch, readState, html, render} from '../lib/index.js';

import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

import {observer} from '../Utils/IntersectionObserver/SatListEntryObserver.js';

class SatListEntry extends Component{

    rendered = false;

    static get observedAttributes() {return ['data-focused']; }

    selectSatellite() {
        const satellite = satellitesCollection[this.getAttribute('data-id')];
        if (!satellite) {
            throw new ReferenceError(`Unable to find satellite id ${this.getAttribute('data-id')}`);
        }

        if (satellite.focused) {
            dispatch(Actions.setFocusedObject, null);
        } else {
            dispatch(Actions.setFocusedObject, satellite.id);
        }
    }

    remove() {
        this.out();
        const satellite = satellitesCollection[this.getAttribute('data-id')];
        if (!satellite) {
            throw new ReferenceError(`Unable to find satellite id ${this.getAttribute('data-id')}`);
        }
        dispatch(Actions.TLERemoveActivated, [this.getAttribute('data-id')]);
    }

    hover() {
        const satellite = satellitesCollection[this.getAttribute('data-id')];
        if (!satellite) {
            throw new ReferenceError(`Unable to find satellite id ${this.getAttribute('data-id')}`);
        }

        satellite.over();

        dispatch(Actions.setHoverObject, satellite.id);
    }

    out() {
        const satellite = satellitesCollection[this.getAttribute('data-id')];
        if (!satellite) {
            throw new ReferenceError(`Unable to find satellite id ${this.getAttribute('data-id')}`);
        }

        satellite.out();

        dispatch(Actions.setHoverObject, null);
    }

    connectedCallback() {
        // Set timeout because is fired instantly on mobile.
        setTimeout(() => observer.observe(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        observer.unobserve(this);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (this.rendered) {
            // Avoid render before intersection triggerd it
            this.renderComponent();
        }
    }

    checkIntersect() {
        if (this.rendered == false) {
            this.renderComponent();
            this.rendered = true;
        }
    }

    renderComponent() {
        const entryClasses = classes({
            'uiSat': true,
            'focused': this.getAttribute('data-focused') == '1'
        });

        render(this, html`
            <div class=${entryClasses} onmouseover=${e => this.hover(e)} onmouseout=${e => this.out(e)}>
                <div class="content">
                    <span onclick=${(e) => this.selectSatellite(e)}>${this.getAttribute('data-name')}</span>
                </div>
                <div class="close" onmouseup=${e => this.remove(e)}><div class="inner"></div></div>
            </div>
        `);
    }
}

customElements.define("sat-list-entry", SatListEntry);

export default SatListEntry;
