import * as THREE from 'three';
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import {readState, subscribeToState} from '../../lib/index.js';

import {orbits} from '../Scene.js';

import {getDate} from '../../Utils/ApplicationDate.js';
import debounce from '../../Utils/debounce.js';


let geometries = {};
let orbitLines = null;

const addOrbitGeometry = function(id, geometry) {

    geometries[id] = geometry;
    debouncedRender();
}

const removeOrbitGeometry = function(id) {
    delete geometries[id];
    debouncedRender();
}


const displayOrbits = function() {

    orbits.remove(...orbits.children);
    const arrayGeometries = Object.values(geometries);

    if (arrayGeometries.length) {
        const mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(Object.values(geometries));
        const material = new THREE.LineBasicMaterial({vertexColors: true});

        orbitLines = new THREE.LineSegments(mergedGeometries, material);

        orbits.add(orbitLines);
    } else {
        orbitLines = null;
    }
}


const debouncedRender = debounce(displayOrbits, 10);

export {addOrbitGeometry, removeOrbitGeometry};
