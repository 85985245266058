import * as THREE from 'three';
import {geodeticToEcf, degreesToRadians} from 'satellite.js';

import ThreeGeo from '../dep/three-geo.old.js';

import {earthRadius} from '../Settings.js';
import {fpvGroup, scene} from '../Scene.js';

import {readState, subscribeToState} from '../../lib/index.js';

import {rotateAroundObjectAxis, rotateAroundWorldAxis} from '../../Utils/rotateObject.js';
import degToRad from '../../Utils/degToRad.js';

const tokenMapbox = 'pk.eyJ1Ijoic3BvcGlsYSIsImEiOiJja2NkNG9qZzQwYjhsMnVtY2R2aWNnZ2o3In0.wlx5pZXcpp83kFIfgIw2Zg';

const initFPV = function() {

    const geoloc     = readState('geolocation');

    const observer = {
        latitude: degToRad(geoloc.latitude),
        longitude: degToRad(geoloc.longitude),
        height: 0,
    };

    var observerEcf = geodeticToEcf(observer);
    observerEcf.x = (observerEcf.x / 6371) * earthRadius;
    observerEcf.y = (observerEcf.y / 6371) * earthRadius;
    observerEcf.z = (observerEcf.z / 6371) * earthRadius;

    fpvGroup.position.x = observerEcf.x;
    fpvGroup.position.y = observerEcf.z;
    fpvGroup.position.z = - observerEcf.y;

    fpvGroup.rotation.set(0, 0, 0);
    fpvGroup.updateMatrix();

    rotateAroundObjectAxis(fpvGroup, new THREE.Vector3(0, 0, 1), observer.latitude)
    rotateAroundWorldAxis(fpvGroup, new THREE.Vector3(0, 1, 0), observer.longitude)
    rotateAroundObjectAxis(fpvGroup, new THREE.Vector3(0, 1, 0), degreesToRadians(90))

    /*
    const walls = new THREE.LineSegments(
        new THREE.EdgesGeometry(new THREE.BoxBufferGeometry(1, 1, 1)),
        new THREE.LineBasicMaterial({color: 0xcccccc}));
    walls.position.set(0, 0, 0);
    fpvGroup.add(walls);
    fpvGroup.add(new THREE.AxesHelper(1));

    const circle = new THREE.LineSegments(
        new THREE.EdgesGeometry(new THREE.CircleBufferGeometry(1, 32)),
        new THREE.LineBasicMaterial({color: 0xcccccc}));
    walls.position.set(0, 0, 0);
    fpvGroup.add(circle);
    */

    /*
     * New version
    (async () => { // main
        const tgeo = new ThreeGeo({
            tokenMapbox: 'pk.eyJ1Ijoic3BvcGlsYSIsImEiOiJja2NkNG9qZzQwYjhsMnVtY2R2aWNnZ2o3In0.wlx5pZXcpp83kFIfgIw2Zg',
        });

        // X East
        // Y North
        // Z Up
;
        //[46.5763, 7.9904], // [lat, lng]
        const terrain = await tgeo.getTerrainRgb(
            [geoloc.latitude, geoloc.longitude], // [lat, lng]
            63.71,               // radius of bounding circle (km)
            8);               // zoom resolution

        fpvGroup.add(terrain);
    })();
    */

    const scale = 15;
    const tgeo = new ThreeGeo({
        tokenMapbox: tokenMapbox,
        unitsSide: 1 / scale
    });

    const terrain = new THREE.Group();
    fpvGroup.add(terrain);

    tgeo.getTerrain([geoloc.latitude, geoloc.longitude], 63.71 / scale, 14, {
        onRgbDem: meshes => {
            // Meshes are here
            meshes.forEach(mesh => {
                terrain.add(mesh)
            });
        },
        onSatelliteMat: mesh => {
            // Texture is here
        },
    });
}

/*
const getElevation = function(position, cb) {

    let latitude  = position.latitude + '';
    let longitude = position.longitude + '';

    const expLat  = latitude.split('.');
    const expLng  = longitude.split('.');

    latitude  = expLat[0] + '.' + expLat[1].padEnd(15, 0);
    longitude = expLng[0] + '.' + expLng[1].padEnd(15, 0);

    const accessToken = 'pk.eyJ1Ijoic3BvcGlsYSIsImEiOiJja2NkNG9qZzQwYjhsMnVtY2R2aWNnZ2o3In0.wlx5pZXcpp83kFIfgIw2Zg';
    const query = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${longitude},${latitude}.json?layers=contour&limit=50&access_token=${accessToken}`;

    fetch(query)
        .then(response => response.json())
        .then(response => {
            var elevations = [];
            // For each returned feature, add elevation data to the elevations array
            const allFeatures = response.features;
            for (let i = 0; i < allFeatures.length; i++) {
              elevations.push(allFeatures[i].properties.ele);
            }
            // In the elevations array, find the largest value
            var highestElevation = Math.max(...elevations);

            cb(position, highestElevation);
        });
}
*/

const stopFPV = function() {
    for (var i = fpvGroup.children.length - 1; i >= 0; i--) {
        fpvGroup.remove(fpvGroup.children[i]);
    }
}

const setViewMode = (oldValue, newValue) => {
    if (newValue == 'fpv') {
        initFPV();
    }
    if (oldValue == 'fpv' && newValue != 'fpv') {
        stopFPV();
    }
}

subscribeToState('viewMode', setViewMode);

export {
    initFPV,
    stopFPV
}
