import {addSatellitesFromTLEs, addSatellitesFromConstellationTLEs, removeSatellitesFromIDs, removeSatellitesFromConstellations} from '../App/SceneElements/SatellitesCollection.js';


export default {
    'TLELoaded': [
        (state, payload) => {
            return {"TLE.available": payload};
        }
    ],
    'TLEAddActivated': [
        function(state, payload) {
            return {
                "TLE.activated": [...state.TLE.activated, ...payload]
            }
        },
        function (state, payload) {
            addSatellitesFromTLEs(payload);
        }
    ],
    'TLEAddConstellation': [
        function(state, payload) {
            return {
                "TLE.constellations": [...state.TLE.constellations, payload]
            }
        },
        function (state, payload) {
            addSatellitesFromConstellationTLEs(payload.name, payload.TLEs);
        }
    ],
    'TLERemoveActivated': [
        function(state, payload) {
            let activated = state.TLE.activated;
            activated = activated.filter((sat) => {
                return payload.indexOf(sat[3]) == -1;
            })

            return {
                "TLE.activated": activated
            }
        },
        function(state, payload) {
            removeSatellitesFromIDs(payload);
        }
    ],
    'TLERemoveConstellation': [
        function(state, payload) {
            let constellations = state.TLE.constellations;
            constellations = constellations.filter((constellation) => {
                //return constellation.name !== payload.name;
                return payload.filter(delConst => delConst.name == constellation.name).length == 0;
            })

            return {
                "TLE.constellations": constellations
            }
        },
        function(state, payload) {
            removeSatellitesFromConstellations(payload);
        }
    ],
    'setProjectionValue': [
        (state, payload) => {
            return {"timeProjection.value": payload}
        }
    ],
    'setCoordinateSystem': [
        (state, payload) => {
            return {"coordinateSystem": payload}
        }
    ],
    'setFocalFactor': [
        (state, payload) => {
            return {"focalFactor": payload}
        }
    ],
    'setViewMode': [
        (state, payload) => {
            return {"viewMode": payload}
        }
    ],
    'setCameraHorizontalAngle': [
        (state, payload) => {
            return {"cameraAngles.horizontal": payload}
        }
    ],
    'setCameraVerticalAngle': [
        (state, payload) => {
            return {"cameraAngles.vertical": payload}
        }
    ],
    'setGeolocation': [
        (state, payload) => {
            return {"geolocation": payload}
        }
    ],
    'setFocusedObject': [
        (state, payload) => {
            return {"focusedObject": payload}
        }
    ],
    'setHoverObject': [
        (state, payload) => {
            return {"hoverObject": payload}
        }
    ],
    'addObjectFromLaunch': [
        (state, payload) => {
            state.objectsFromLauches.push(payload);
            return {"objectsFromLauches": state.objectsFromLauches}
        }
    ],
    'removeObjectFromLaunch': [
        (state, payload) => {
            return {"objectsFromLauches": state.objectsFromLauches.filter(launchId => launchId != payload)}
        }
    ]
};
