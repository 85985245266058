import {Actions, classes, dispatch, Page, readState, html, render} from '../lib/index.js';

import {TLEImport} from '../Utils/TLEImport.js';
import {selectTLEsFromUrl} from '../Utils/TLEsQueryParameter.js';
import {sameLauncheObjects} from '../Utils/sameLaunchObjects.js';

import TimeSlider from '../Components/TimeSlider.js';
import PopUpMenu from '../Components/PopUpMenu.js';
import OpenMenuButton from '../Components/OpenMenuButton.js';
import SatList from '../Components/SatList.js';
import AppComponent from '../Components/AppComponent.js';
import SatTooltip from '../Components/SatTooltip.js';
import FPVUI from '../Components/FPVUI.js';
import FocusedPopUp from '../Components/FocusedPopUp.js';

import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

import PageAppActions from './PageAppActions.js';


class PageApp extends Page {

    constructor() {
        super()
    }

    actionsRegistry = PageAppActions;

    static get mappedProperties() {
        return [
            'TLE.activated',
            'TLE.constellations',
            'hoverObject',
            'viewMode',
            'focusedObject',
        ]
    }

    static get initialState() {
        return {
            menuOpen: false,
            satListOpen: false
        }
    };

    toggleMenuOpening(e) {
        if (this.getState('menuOpen')) {
            this.setState({menuOpen: false});
        } else {
            this.setState({menuOpen: true});
        }
    }

    toggleSatListOpening(e) {
        if (this.getState('satListOpen')) {
            this.setState({satListOpen: false});
        } else {
            this.setState({satListOpen: true});
        }
    }

    connectedCallback() {
        super.connectedCallback();

        if (readState('TLE.available') == false) {
            TLEImport(selectTLEsFromUrl);
        }

        const coordinate = this.getState('glucose.location.queryString.coordinate');
        if (coordinate) {
            dispatch(Actions.setCoordinateSystem, coordinate);
        }
    }

    showTooltip(viewMode, hoverObject, focusedObject){

        if (hoverObject) {
            if (viewMode == 'fpv' && focusedObject != null) {
                return false;
            }

            return true
        }

        return false;
    }

    renderComponent() {
        const activatedCount     = this.getState('TLE.activated').length
        const constellationCount = this.getState('TLE.constellations').length;
        const totalCount         = ((activatedCount ? activatedCount : 0) + (constellationCount ? constellationCount : 0));
        const openSatListClasses = classes({
            'opened': this.getState('satListOpen'),
            'show' : totalCount > 0
        });

        const hoverObject   = satellitesCollection[this.getState('hoverObject')];
        const focusedObject = satellitesCollection[this.getState('focusedObject')];
        const viewMode      = this.getState('viewMode');

        render(this, html`
            <app-component id="canvas" />

            <open-menu-button id="openMenuButton" onclick=${e => this.toggleMenuOpening(e)}></open-menu-button>
            ${this.getState('menuOpen') ? html`
                <pop-up-menu id="popUpMenu" onclose=${e => this.toggleMenuOpening(e)}></pop-up-menu>
            ` : null}
            <time-slider id="timeSlider"></time-slider>

            <div id="openSatList" class=${openSatListClasses} onclick=${e => this.toggleSatListOpening(e)}>
                <div class="inner"></div>
            </div>
            ${(this.getState('satListOpen') && totalCount > 0) ? html`
                <sat-list id="satList" onclose=${e => this.toggleSatListOpening(e)}></sat-list>
            ` : null}

            ${this.showTooltip(viewMode, hoverObject, focusedObject) ? html`
                <sat-tooltip
                    id="tooltip"
                    style=${`top: ${hoverObject.toScreenXY().y}px; left: ${hoverObject.toScreenXY().x}px`}
                    .satellite=${hoverObject}
                />
            ` : null}

            ${viewMode == 'fpv' ? html`
                <fpv-ui />
            ` : null}

            ${focusedObject ? html`
                <focused-info id="focusedInfo" .satellite=${focusedObject} />
            ` : null}
        `);
    }
}

customElements.define("page-app", PageApp);

export default PageApp;
