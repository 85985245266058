import {Component, html, render} from '../lib/index.js';

class FPVCompass extends Component {
    constructor(params) {
        super()
    }

    static get mappedProperties() {
        return [
            'cameraAngles.horizontal'
        ]
    }

    renderComponent() {
        const angle = this.getState('cameraAngles.horizontal');
        render(this, html`
            <svg id="compass" width="36" height="36" version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="17" fill-opacity="0" stroke="#2BD0FF" stroke-opacity="0.8" stroke-width="1"/>
                <path d="M18 18 L 18 3 A 16 16 0 0 1 30 9" fill="#9EF3F2" fill-opacity="0.8" style=${`transform: rotate(${angle}rad)`} />
            </svg>
        `);
    }
}

customElements.define("fpv-compass", FPVCompass);

export default FPVCompass;
