import {Actions, dispatch, readState} from '../lib/index.js';

import parseTLEs from './TLEParser.js';

const TLEUrl          = "https://projects.spope.fr/celestrak/active/";

const TLEImport = function(callback) {
    fetch(TLEUrl)
        .then(response     => response.text())
        .then(textResponse => parseTLEs(textResponse))
        .then(TLEs         => {
            setTLEsIntoState(TLEs);
            if (callback) {
                callback(TLEs);
            }
        });
}

const setTLEsIntoState = function(TLEs) {
    dispatch(Actions.TLELoaded, TLEs);
}

const importFromUrl = function(url, callback) {
    fetch(url)
        .then(response     => response.text())
        .then(textResponse => parseTLEs(textResponse))
        .then(TLEs         => {
            if (callback) {
                callback(TLEs);
            }
        });
}

export {TLEImport, importFromUrl};
