import {Actions, classes, Component, dispatch, html, render} from '../lib/index.js';

class FocusedPopUp extends Component {
    connectedCallback() {
        super.connectedCallback();

        //force style computation
        this.offsetWidth;
        this.classList.add('animation');

        this.bindParametersChange();

    }

    disconnectedCallback() {
        clearTimeout(this.timeout);
    }

    static get mappedProperties() {
        return [
            'viewMode',
            'focusedObject',
            'objectsFromLauches'
        ]
    }

    bindParametersChange() {
        if (this.satellite) {
            this.setState({
                height: this.satellite.height,
                velocity: this.satellite.velocity
            });
        }

        this.timeout = setTimeout(() => this.bindParametersChange(), 100);
    }

    stop() {
        dispatch(Actions.setViewMode, 'orbital');
        dispatch(Actions.setFocusedObject, null);
    }

    showCamChange() {
        if (this.getState('viewMode') != 'satCam') {
            return html`<div class="action first" onclick=${e => this.setSatCam()}>Sat Cam</div>`;
        }
        return html`<div class="action first" onclick=${e => this.setOrbitalCam()}>Orbital Cam</div>`;
    }

    showSameLaunchObjects() {
        if (this.satellite.constellation) {
            if (this.getState('objectsFromLauches').includes(this.satellite.launchId)) {
                return html`<div class="action" onclick=${e => this.unselectObjectsFromLaunch()}>Hide same launch objects</div>`;
            } else {
                return html`<div class="action" onclick=${e => this.selectObjectsFromLaunch()}>Show same launch objects</div>`;
            }
        }
    }

    unselectObjectsFromLaunch() {
        dispatch(Actions.removeObjectFromLaunch, this.satellite.launchId);
    }


    selectObjectsFromLaunch() {
        dispatch(Actions.addObjectFromLaunch, this.satellite.launchId);
    }

    setSatCam(e) {
        dispatch(Actions.setViewMode, 'satCam');
    }

    setOrbitalCam(e) {
        dispatch(Actions.setViewMode, 'orbital');
    }

    renderComponent() {

        if (this.satellite) {
            render(this, html`
                <div class="inner">
                    ${this.satellite.name}
                    <div class="info first"><small>Altitude</small> <span>${this.getState('height')} km</span></div>
                    <div class="info"><small>Velocity</small> <span>${this.getState('velocity')} km/s</span></div>

                    ${this.showCamChange()}
                    ${this.showSameLaunchObjects()}
                </div>
                <div class="close" onclick=${e => this.stop()}><div class="inner"></div></div>
            `);
        }
    }
}

customElements.define("focused-info", FocusedPopUp);

export default FocusedPopUp;
