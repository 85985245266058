import * as THREE from 'three';

import {initScene, killScene} from './Scene.js';
import {initCameras, getCamera, killCameras} from './Cameras/AppCamera.js';
import {initControls, killControls} from './Controls/AppControls.js';
import {getRenderer, renderApp, stopRenderApp} from './renderer.js'

import {initEarth, killEarth} from './SceneElements/Earth.js';
import {initFPV, stopFPV} from './SceneElements/FPV.js';
import {initSun, killSun} from './SceneElements/Sun.js';
import {initUniverse, killUniverse} from './SceneElements/Universe.js';


import {initIntersection} from '../Utils/Intersection.js';

//DEBUG
import {initDebug} from './debug.js';


let renderer;
const bootApp = (element, {appWidth, appHeight}) => {

    initScene();
    initCameras({appWidth, appHeight});
    initControls({element});
    initIntersection(element, appWidth, appHeight);

    renderer = getRenderer(appWidth, appHeight);
    element.appendChild(renderer.domElement);

    initUniverse();
    initSun();
    initEarth();

    if (process.env.ENV == "dev") {
        initDebug(element);
    }

    renderApp();
}

const stopApp = () => {
    stopRenderApp();
    killScene();
    killEarth();
    killSun();
    killUniverse();
    killCameras();
    killControls();
    renderer = null;
}


export {bootApp, stopApp};
