import * as THREE from 'three';

import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';

import Controls from './Controls.js';
import {getCamera} from '../Cameras/AppCamera.js';
import {earthRadius} from '../Settings.js';

import {earthObject} from '../SceneElements/Earth.js';
import {satellitesCollection} from '../SceneElements/SatellitesCollection.js';

import {readState} from '../../lib/index.js';

class Orbital extends Controls{

    constructor(sceneDomElement) {
        super(sceneDomElement);
        this.controls = new OrbitControls(getCamera().camera, this.sceneDomElement);

        this.controls.minDistance = earthRadius + (earthRadius * 0.2);
        this.controls.maxDistance = 20000;
        this.controls.noPan       = true;
        this.controls.enablePan   = false;
        this.controls.zoomSpeed   = 0.5;

        const focusedObject = readState('focusedObject');
        if (focusedObject) {
            this.setFocusedObject(null, focusedObject);
        }
    }

    setFocusedObject(oldObject, newObject) {
        if (this.controls) {
            if (newObject == null) {
                newObject = earthObject;
                this.controls.minDistance = earthRadius + (earthRadius * 0.2);
            } else {
                newObject = satellitesCollection[newObject];
                this.controls.minDistance = (earthRadius * 0.2);
            }
            this.setTarget(newObject);
        }
    }

    stop() {
        super.stop();
        this.controls.dispose();
    }

    update() {
        if (this.target) {
            this.updateLookAt();
            this.controls.update();
        }
    }

    updateLookAt() {
        if (this.target) {
            // In ECI satellites groups is rotated, retreiving world position
            const worldPosition = new THREE.Vector3();
            worldPosition.setFromMatrixPosition( this.target.getObject().matrixWorld );
            this.controls.target = new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z);
        }
    }

    getSatelliteScaleFactor() {
        return 1;
    }
}

export default Orbital;
