import {Actions, classes, dispatch, Link, Page, readState, html, render} from '../lib/index.js';

import PageAboutActions from './PageAboutActions.js';

class PageAbout extends Page {

    constructor() {
        super()
    }

    actionsRegistry = PageAboutActions;

    renderComponent() {
        render(this, html`
            <div class="description">
                <div class="row">
                    <h1>ARbit</h1>

                    <div>
                        <p>This site shows orbits of satellite tracked by NORAD. It uses <a href="https://en.wikipedia.org/wiki/Two-line_element_set" title="ikipedia TLE definition">TLE</a> parsed with <a href="https://github.com/shashwatak/satellite-js" title="Github Satellite-js">satellite-js</a>. You can import any TLE or search throuh a list of active satellites retrieved from <a href="https://www.celestrak.com/" title="Celestrack.com">Celestrak.com</a>. You can visualize orbits in <a href="https://en.wikipedia.org/wiki/ECEF" title="Wikipedia ECEF">ECEF</a> or <a href="https://en.wikipedia.org/wiki/ECI" title="Wikipedia ECI">ECI</a> coordinate. Augmented Reality (AR) view from your location is currenlty in beta.</p>
                        <br />
                        <p>Sun position is estimated from current time and date. Earth tilt "vary" form -23.45° (December 22) to +23.45° (June 21). Today tilt is calculated with <a href="http://www.pveducation.org/pvcdrom/2-properties-sunlight/declination-angle" title="Earth Tilt calculation" target="_blank">this equation</a>.</p>
                        <br />
                        <p>The scene is made using <a href="http://threejs.org/" title="Three.js" target="_blank">Three.js</a>. Earth shader is made by <a href="http://http.developer.nvidia.com/GPUGems2/gpugems2_chapter16.html" title="Sean O'Neil Atmospheric Scattering" target="_blank">Sean O'Neil</a>, ported for use with three.js/WebGL by James Baicoianu.</p>
                        <br />
                        <p>Milky Way picture source : <a href="http://www.eso.org/public/images/eso0932a/" title="ESO Milky Way Panorama">ESO / S.Brunier</a>.<br />
                        If you have any questions or suggestions, feel free to conctact me on <a href="https://twitter.com/spopila" title="Spopila" target="_blank">Twitter</a>.</p>
                        <br />
                        <p>Made by <a href="http://spope.fr" title="Spope's portfolio">Spope</a>.</p>
                        <br />
                        <p><a is="glucose-a" data-route="app">Back</a></p>
                    </div>
                </div>
            </div>
        `);
    }
}

customElements.define("page-about", PageAbout);

export default PageAbout;
