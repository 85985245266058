import {subscribeToState} from '../lib/index.js';

import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

let sameLauncheObjects = [];

const selectObjectsFromLaunch = function(old, launches) {
    Object.values(satellitesCollection).map(satellite => {
        if (launches.includes(satellite.launchId)) {
            satellite.selectSameLaunchObject();
            sameLauncheObjects.push(satellite);
        } else {
            if (sameLauncheObjects.includes(satellite)) {
                satellite.unselectSameLaunchObject();
                sameLauncheObjects = sameLauncheObjects.filter(sat => sat.id != satellite.id);
            }
        }
    })
}

subscribeToState('objectsFromLauches', selectObjectsFromLaunch);

export {sameLauncheObjects};
