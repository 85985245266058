import {getDate} from './ApplicationDate.js';

let todayInclination;

const getEarthSeasonalInclination = function() {
    if (todayInclination) {
        return todayInclination;
    }
    const now         = getDate();
    const start       = new Date(now.getFullYear(), 0, 0);
    const diff        = now - start;
    const oneDay      = 1000 * 60 * 60 * 24;
    const day         = Math.floor(diff / oneDay);

    const radianAngle = Math.asin(Math.sin(23.45 * (Math.PI/180)) * Math.sin(((360/365) * (day - 81)) * (Math.PI/180) ));

    todayInclination = 180 * radianAngle / Math.PI;

    return todayInclination;
}

export default getEarthSeasonalInclination;
