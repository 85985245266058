import {PointerLockControls} from 'three/examples/jsm/controls/PointerLockControls.js';

import {getCamera} from '../Cameras/AppCamera.js';


class FPVMouse {

    #zoomSpeed = 0.5;
    #eventsCallback;
    sceneDomElement;

    constructor(sceneDomElement) {

        this.#eventsCallback = {};
        this.sceneDomElement = sceneDomElement;
        this.controls = new PointerLockControls(getCamera().camera, this.sceneDomElement);

        this.bindedLockPointerCamera = this.lockPointerCamera.bind(this);
        this.bindedOnMouseWheel      = this.onMouseWheel.bind(this);

        this.sceneDomElement.addEventListener('click', this.bindedLockPointerCamera, false );

        this.controls.dispatchEvent = (e) => {
            if (e.type == 'change') {
                getCamera().updateCameraAngles();
                if (this.#eventsCallback['rotation']) {
                    this.#eventsCallback['rotation'].call();
                }
            }
        }

        this.sceneDomElement.addEventListener('wheel', this.bindedOnMouseWheel, false );
    }

    listenEvent(type, cb) {
        this.#eventsCallback[type] = cb;
    }

    onMouseWheel(e) {
        e.preventDefault();
        e.stopPropagation();

        let factor = getCamera().getFocalFactor();
        if (event.deltaY < 0) {
            //zoom out
            factor *= Math.pow( 0.95, this.#zoomSpeed );
        } else if ( event.deltaY > 0) {
            //zoom in
            factor /= Math.pow( 0.95, this.#zoomSpeed );
        }

        getCamera().setFocalFactor(factor);
    }

    lockPointerCamera() {
        this.controls.lock();
    }

    stop() {
        this.sceneDomElement.removeEventListener('click', this.bindedLockPointerCamera);
        this.sceneDomElement.removeEventListener('wheel', this.bindedOnMouseWheel);
        this.controls.disconnect();
    }
}

export default FPVMouse;
