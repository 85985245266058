import {Actions, classes, Component, dispatch, html, render} from '../lib/index.js';


class TimeSlider extends Component{
    connectedCallback() {
        super.connectedCallback();
        const graduation = this.setGraduation(this.querySelector('#graduation_inner').offsetWidth);
    }

    static get mappedProperties() {
        return [
            'timeProjection.value'
        ]
    }

    onInput(e) {
        dispatch(Actions.setProjectionValue, -e.target.value);
    }

    resetTimeProjection(e) {
        dispatch(Actions.setProjectionValue, 0);
    }

    setGraduation(width) {
        const unitWidth = 40;
        const unit  = `<div class="b"></div> <div class="s"></div> <div class="s"></div> <div class="s"></div>`;
        const count = Math.floor(width / unitWidth );
        let diff    = width - (unitWidth * count);

        const items = Array.from({length: count});

        render(this.querySelector('#graduation_inner'), html`
            ${items.map(i =>
                html`<div class="b"></div> <div class="s"></div> <div class="s"></div> <div class="s"></div>`
            )}
            ${
                (diff > 1) ? html`<div class="b l"></div>` : null
            }
        `);

        this.querySelector('#graduation_inner').style.marginLeft = `${diff / 2}px`;
    }

    renderComponent() {
        render(this, html`
            <div id="timeValue">
                <div id="timeValue_inner" class=${classes({'negative': this.getState('timeProjection.value') > 0 })} onclick=${e => this.resetTimeProjection(e)}>
                    ${(this.getState('timeProjection.value') > 0 ? '- ' : '+ ')}
                    <strong>${Math.abs(Math.round(this.getState('timeProjection.value')))}</strong> min
                </div>
            </div>
            <div id="underlay">
                <input id="timeRange" type="range" oninput=${e => this.onInput(e)} .value="${-this.getState('timeProjection.value')}" min="-100" max="100" step="0.01" >
            </div>
            <div id="graduation">
                <div id="graduation_inner"></div>
            </div>
        `);

    }
}

customElements.define("time-slider", TimeSlider);

export default TimeSlider;
