import {readState, setActionRegistry} from '../lib/index.js';


export default {
    'test': [
        (state, payload) => {
            return {"test": payload};
        }
    ]
};
