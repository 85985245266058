import {Actions, dispatch} from '../lib/index.js';

let endCallback = null;
const askGeolocation = function(callback) {
    endCallback = callback;
    navigator.geolocation.getCurrentPosition(callbackGeolocation, errorGeolocation);
}

const callbackGeolocation = function(position) {

    //St Lo
    //const geo = {
        //latitude: 45.387179,
        //longitude: 5.735976,
        //altitude: 0
    //}

    //Tokyo
    //const geo = {
        //latitude: 35.6895,
        //longitude: 139.69171,
        //altitude: 0
    //}

    //Pau
    //const geo = {
        //latitude: 43.2951,
        //longitude: -0.370797,
        //altitude: 0
    //};
    const geo = position.coords;

    getElevation(geo, (elevation) => {
        let correctPosition = {
            latitude:  geo.latitude,
            longitude: geo.longitude,
            altitude: elevation
        };

        dispatch(Actions.setGeolocation, correctPosition);

        if (endCallback) {
            endCallback();
        }
    });
}

const getElevation = function(position, cb) {

    let latitude  = position.latitude + '';
    let longitude = position.longitude + '';

    const expLat  = latitude.split('.');
    const expLng  = longitude.split('.');

    latitude  = expLat[0] + '.' + expLat[1].padEnd(13, 0);
    longitude = expLng[0] + '.' + expLng[1].padEnd(13, 0);

    const accessToken = 'pk.eyJ1Ijoic3BvcGlsYSIsImEiOiJja2NkNG9qZzQwYjhsMnVtY2R2aWNnZ2o3In0.wlx5pZXcpp83kFIfgIw2Zg';
    const query = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${longitude},${latitude}.json?layers=contour&limit=50&access_token=${accessToken}`;

    fetch(query)
        .then(response => response.json())
        .then(response => {
            var elevations = [];
            console.log(response);
            // For each returned feature, add elevation data to the elevations array
            const allFeatures = response.features;
            for (let i = 0; i < allFeatures.length; i++) {
              elevations.push(allFeatures[i].properties.ele);
            }
            // In the elevations array, find the largest value
            var highestElevation = Math.max(...elevations);

            cb(highestElevation);
        });
}

const errorGeolocation = function(error) {
    console.log(error);
}



export default askGeolocation;
