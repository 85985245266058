import * as THREE from 'three';

import {readState, subscribeToState} from '../../lib/index.js';

import {satellites} from '../Scene.js';

import {getDate} from '../../Utils/ApplicationDate.js';
import debounce from '../../Utils/debounce.js';

//const defaultColor = 0xc1f354;
//const redColor     = 0xF2583D;
const colorPalette = {
    default    : 0xc1f354,
    sameLaunch : 0xF2499A
};


const geometry = new THREE.SphereBufferGeometry(1, 10, 10);
const material = new THREE.MeshBasicMaterial({
    vertexColors: true
});

let sats                 = {};
let meshes               = null;
let refreshPositionAsked = false;
let refreshColorAsked    = false;

/**
 * Sats contains sat id and local matrix for each sats
 */
const addSatelliteInstance = function(id, color) {
    sats[id] = {
        id: id,
        matrix: null,
        color: color
    }
    debouncedRender();
}
const removeSatelliteInstance = function(id) {
    delete sats[id];
    debouncedRender();
}

/**
 * Initialize InstancedMesh with sats length
 */
const displaySatellites = function () {
    setSatellitesColor();
    satellites.remove(...satellites.children);

    const arraySatellites = Object.values(sats);
    if (arraySatellites.length) {
        meshes    = new THREE.InstancedMesh(
            geometry,
            material,
            arraySatellites.length
        );

        satellites.add(meshes);
    } else {
        meshes = null;
    }
}

/**
 * Called by AppSatellite to update satellite postion / scale
 */
const updateSatelliteMatrix = function(id, matrix) {

    if (sats[id]) {
        sats[id].matrix = matrix;

        if (refreshPositionAsked === false) {
            refreshPositionAsked = true;
            window.requestAnimationFrame(updatePositions);
        }
    }
}

const updateSatelliteColor = function(id, color) {
    if (sats[id]) {
        sats[id].color = color;

        if (refreshColorAsked === false) {
            refreshColorAsked = true;
            window.requestAnimationFrame(displaySatellites);
        }
    }
}

const setSatellitesColor = function() {
    const arraySatellites = Object.values(sats);
    const _color    = new THREE.Color();
    const colors    = new Float32Array(arraySatellites.length * 3);

    if (arraySatellites.length) {
        arraySatellites.map((param, i) => {
            if (param.color) {
                _color.setHex(colorPalette[param.color]);
                _color.toArray(colors, i * 3)
            }
        });

        geometry.setAttribute('color', new THREE.InstancedBufferAttribute( colors, 3 ) );
    }
    refreshColorAsked = false;
}

/**
 * Send sats matrixs to InstancedMesh to update position
 */
const updatePositions = function() {

    const arraySatellites = Object.values(sats);
    if (arraySatellites.length && meshes) {
        arraySatellites.map((param, i) => {
            if (param.matrix) {
                meshes.setMatrixAt(i, param.matrix);
            }
        });

        meshes.instanceMatrix.needsUpdate = true;
    }
    refreshPositionAsked = false;
}


/**
 * Retrieve a satellite ID from its instance Id
 */
const getSatelliteIdFromInstanceId = function(instanceId) {

    const arraySatellites = Object.values(sats);
    const id = arraySatellites[instanceId].id;

    return id;

}

const debouncedRender   = debounce(displaySatellites, 1);

export {addSatelliteInstance, updateSatelliteColor, updateSatelliteMatrix, getSatelliteIdFromInstanceId, removeSatelliteInstance};
