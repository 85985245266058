import * as THREE from 'three';

import {earthRadius} from '../Settings.js';
import {earthGroup, scene} from '../Scene.js';
import {getCamera} from '../Cameras/AppCamera.js';

import {eulerLight} from './Sun.js';

import getUniformsEarth from './uniforms/uniformsEarth.js';
import getUniformsAtmosphere from './uniforms/uniformsAtmosphere.js';

import earthShader from './shaders/earthShader.js';
import atmosphereShader from './shaders/atmosphereShader.js';

import {readState, subscribeToState} from '../../lib/index.js';

import {getDate} from '../../Utils/ApplicationDate.js';
import getEarthSeasonalInclination from '../../Utils/getEarthSeasonalInclination.js';

//import earthDiffuse from '../../../img/earth/EarthMapAtmos_2500x1250.jpg';
//import earthMask    from '../../../img/earth/EarthMask_2500x1250.jpg';
import earthDiffuse from '../../../img/earth/earthBig-cloud.jpg';
import earthMask    from '../../../img/earth/water_8k.jpg';
//
//import earthMaskBig from '../../../img/earth/water_8k.jpg';
//import earthBig     from '../../../img/earth/earthBig.jpg';
import earthNight   from '../../../img/earth/earthNight.jpg';

/////////////
// Parameters
const atmosphere = {
    Kr: 0.0025,
    Km: 0.0010,
    ESun: 20.0,
    g: -0.950,
    innerRadius: earthRadius,
    outerRadius: earthRadius * 1.015,
    wavelength: [0.650, 0.570, 0.475],
    scaleDepth: 0.25,
    mieScaleDepth: 0.1
};

const earthPoly      = 80;
const atmospherePoly = 150;
//const groundPoly     = 200;

/////////////////
// Actual Objects
let earthObject;
let earthMaterial;
//

let atmosphereObject;
let atmosphereMaterial;

//let groundObject;
//let groundMaterial;

let absoluteLight;

/////////////////////
// Dynamic properties
let earthRotation;
let lastEarthRotationTime;
let inclination;
let coordinateSystem;

const killEarth = function() {

    earthRotation         = null;
    lastEarthRotationTime = null;
    inclination           = null;
    coordinateSystem      = null;
    //
    earthObject   = null;
    earthMaterial = null;
    //

    atmosphereObject   = null;
    atmosphereMaterial = null;

    //groundObject   = null;
    //groundMaterial = null;

    absoluteLight  = null;
}

const setCoordinateSystem = (oldValue, newValue) => {
    coordinateSystem = readState('coordinateSystem');

    switch (newValue) {
        case 'eci':
            initEci();
        break;
        case 'ecef':
            initEcef();
        break;
    }
}

const setViewMode = (oldValue, newValue) => {
    if (newValue == 'fpv') {
        setFpv();
    }
    if (oldValue == 'fpv' && newValue != 'fpv') {
        stopFpv();
    }
}

const initEarth = function() {
    earthRotation         = 0;
    lastEarthRotationTime = null;
    inclination           = 0;
    coordinateSystem      = null;

    initAtmosphere();
    initEarthObject();

    setCoordinateSystem(null, readState('coordinateSystem'));

    subscribeToState('coordinateSystem', setCoordinateSystem);
    subscribeToState('viewMode', setViewMode);
}

const initEarthObject = function() {
    let diffuse      = new THREE.TextureLoader().load(earthDiffuse);
    let diffuseNight = new THREE.TextureLoader().load(earthNight);
    let specular     = new THREE.TextureLoader().load(earthMask);


    diffuse.minFilter      = THREE.LinearFilter;
    diffuseNight.minFilter = THREE.LinearFilter;
    specular.minFilter     = THREE.LinearFilter;

    earthMaterial = new THREE.ShaderMaterial({
        uniforms: getUniformsEarth(
            atmosphere,
            earthRotation,
            inclination,
            diffuse,
            diffuseNight,
            specular
        ),
        vertexShader: earthShader.vertexEarth,
        fragmentShader: earthShader.fragmentEarth
    });

    diffuse      = null;
    diffuseNight = null;
    specular     = null;

    const geometry = new THREE.SphereGeometry(earthRadius, earthPoly, earthPoly);

    earthObject               = new THREE.Mesh(geometry, earthMaterial);
    earthObject.castShadow    = true;
    earthObject.receiveShadow = true;
    earthObject.getObject     = () => earthObject;

    earthGroup.add(earthObject);
}

const initAtmosphere = function() {
    atmosphereMaterial = new THREE.ShaderMaterial({
        uniforms: getUniformsAtmosphere(atmosphere, inclination),
        vertexShader: atmosphereShader.vertexAtmosphere,
        fragmentShader: atmosphereShader.fragmentAtmosphere
    });

    atmosphereMaterial.side = THREE.BackSide;
    atmosphereMaterial.transparent = true;

    const geometry = new THREE.SphereGeometry(atmosphere.outerRadius, atmospherePoly, atmospherePoly);

    atmosphereObject = new THREE.Mesh(geometry, atmosphereMaterial);

    scene.add(atmosphereObject);
}

//const initGround = function() {
    //if (groundObject == undefined) {
        //groundMaterial = new THREE.MeshBasicMaterial({
            //'color': 0x183e7a
        //});

        //const geometry = new THREE.SphereGeometry(earthRadius, groundPoly, groundPoly)

        //groundObject = new THREE.Mesh(geometry, groundMaterial);
        //groundObject.castShadow    = true;
        //groundObject.receiveShadow = true;

        //earthGroup.add(groundObject);
    //}
//}
//const unsetGround = function() {
    //earthGroup.remove(groundObject);
    //groundObject   = undefined;
    //groundMaterial = undefined;
//}

const initEcef = function() {
    inclination = 0;
    earthGroup.rotation.set(0, 0, 0);
    earthGroup.updateMatrix();

    earthMaterial.uniforms.rotationY.value   = 0;
    earthMaterial.uniforms.inclination.value = 0;
}

const initEci =  function() {
    //Seasonal inclination
    inclination      = getEarthSeasonalInclination() * (Math.PI / 180);
    //Hour rotation
    earthRotation    = getEarthRotation();

    //Seasonal inclination of light (to compensate for geomerty inclination)
    absoluteLight           = new THREE.Vector3(0, 0, 1);
    const matrixInclination = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(-inclination, 0, 0));
    const matrixRotation    = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(0, -earthRotation, 0));
    absoluteLight.applyMatrix4(matrixInclination).applyMatrix4(matrixRotation);

    earthMaterial.uniforms.v3LightPositionSpec.value = new THREE.Vector3(0, 0, 1);

    earthGroup.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), inclination);
    earthGroup.rotateOnAxis(new THREE.Vector3(0, 1, 0), earthRotation);

    atmosphereMaterial.uniforms.v3LightPosition.value = new THREE.Vector3(0, 0, 1);
    earthMaterial.uniforms.inclination.value = -inclination;
}

let lastEarthRotationComputation = null;
let angleEarthRotation = null;
const getEarthRotation = function() {

    const now            = getDate();

    //compute each second
    if (lastEarthRotationComputation == null || Math.abs(now.valueOf() - lastEarthRotationComputation) > 1000) {
        lastEarthRotationComputation = now.valueOf();

        const hourAngle      = Math.PI / 12;
        const secondAngle    = (hourAngle / 60) / 60;

        const nowGMT         = new Date(now.valueOf() + (now.getTimezoneOffset() * 60 * 1000));
        const currentMinutes = nowGMT.getMinutes();
        const currentHours   = nowGMT.getHours();
        const currentSeconds = nowGMT.getSeconds();

        const totalSeconds   = currentSeconds + (currentMinutes * 60) + ((currentHours * 60) * 60);
        angleEarthRotation   = (Math.PI / 2) + (secondAngle * totalSeconds);
    }

    return angleEarthRotation;
}

const updateEarthForRender = function() {
    let light;

    if (coordinateSystem == 'ecef') {
        const y          = Math.sin(getEarthSeasonalInclination() * Math.PI / 180);

        light        = new THREE.Vector3(1, y, 0);
        const matrix = new THREE.Matrix4().makeRotationFromEuler(eulerLight);
        light        = light.applyMatrix4(matrix);

    } else {
        const newRotation    = getEarthRotation();
        const deltaRotation  = earthRotation - newRotation;
        earthRotation        = newRotation;

        const matrixRotation = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(0, + deltaRotation, 0));
        light                = absoluteLight.applyMatrix4(matrixRotation);

        earthGroup.rotateOnAxis(new THREE.Vector3(0, 1, 0), - deltaRotation);
        earthMaterial.uniforms.rotationY.value = - earthRotation;
    }

    //Real world position of camera (ignore group)
    const worldPosition = new THREE.Vector3();
    worldPosition.setFromMatrixPosition(getCamera().camera.matrixWorld );
    const cameraPosition = new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z);

    const cameraHeight2 = cameraPosition.length() * cameraPosition.length();

    if (earthMaterial) {
        earthMaterial.uniforms.v3LightPosition.value = light;
        earthMaterial.uniforms.fCameraHeight2.value  = cameraHeight2;
    }

    if (atmosphereMaterial) {
        if (coordinateSystem == 'ecef') {
            //does not move in eci
            earthMaterial.uniforms.v3LightPositionSpec.value = light;
            atmosphereMaterial.uniforms.v3LightPosition.value = light;
        }
        atmosphereMaterial.uniforms.fCameraHeight2.value    = cameraHeight2;
    }

}

const setFpv = function() {
    atmosphereObject.visible = false;
    earthObject.visible      = false;
    //initGround();
}

const stopFpv = function() {
    atmosphereObject.visible = true;
    earthObject.visible      = true;
    //unsetGround();
}

export {
    initEarth,
    earthObject,
    getEarthRotation,
    updateEarthForRender,

    killEarth
}
