import {readState, subscribeToState} from '../lib/index.js';


let projectionValue = 0;

const setProjectionValue = () => {
    projectionValue = readState('timeProjection.value');
}

subscribeToState('timeProjection.value', setProjectionValue);

let date;

// Computed on render
const computeDate = function() {
    date = new Date();
    date.setMinutes(date.getMinutes() - projectionValue);
}

computeDate();

const getDate = function() {

    return new Date(date.getTime());
}

export {getDate, computeDate};
