import {Actions, classes, Component, dispatch, html, render} from '../lib/index.js';

import {bootApp, stopApp} from '../App/Index.js';

class AppComponent extends Component{
    connectedCallback() {
        super.connectedCallback();

        const appWidth  =  document.querySelector('#canvas').offsetWidth;
        const appHeight =  document.querySelector('#canvas').offsetHeight;

        bootApp(this, {appWidth, appHeight});
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        stopApp();
    }

    renderComponent() {
    }
}

customElements.define("app-component", AppComponent);

export default AppComponent;
