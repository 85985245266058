import * as THREE from 'three';

import {Actions, dispatch} from '../../lib/index.js';

import degToRad from '../../Utils/degToRad.js'

import {getCamera} from '../Cameras/AppCamera.js';

class Camera {
    camera;
    initialFocalLength;
    currentFocalFactor = 1;

    #oldAzimuth;
    #oldElevation;

    constructor({appWidth, appHeight}) {
        const distance = this.getDistanceLimit();
        this.camera = new THREE.PerspectiveCamera(50, appWidth / appHeight, distance.near, distance.far);
        this.initialFocalLength = this.camera.getFocalLength();
    }

    getDistanceLimit() {
        return {
            near: 0.01,
            far : 1000000
        }
    }

    getFocalFactorLimit() {
        return {
            min: 0.4,
            max: 3
        };
    }

    setFocalFactor(factor) {
        this.currentFocalFactor = factor;

        if (this.currentFocalFactor < this.getFocalFactorLimit().min) {
            this.currentFocalFactor = this.getFocalFactorLimit().min;
        }
        if (this.currentFocalFactor > this.getFocalFactorLimit().max) {
            this.currentFocalFactor = this.getFocalFactorLimit().max;
        }

        this.camera.setFocalLength(this.initialFocalLength * this.currentFocalFactor);

        dispatch(Actions.setFocalFactor, this.currentFocalFactor);

        return this.currentFocalFactor;
    }

    getFocalFactor() {
        return this.currentFocalFactor;
    }

    getCameraDirection() {
        const direction = new THREE.Vector3( 0, 0, - 1 );

        return (v) => {
            return v.copy(direction).applyQuaternion(this.camera.quaternion);
        }
    }

    updateCameraAngles() {
        this.updateCameraHorizontalAngle();
        this.updateCameraVerticalAngle();
    }

    updateCameraHorizontalAngle() {
        const vector    = new THREE.Vector3();
        const center    = new THREE.Vector3();
        const spherical = new THREE.Spherical();

        vector.copy(this.getCameraDirection()(new THREE.Vector3(0, 0, 0)) ).sub(center);

        spherical.setFromVector3( vector );

        let compass = ((-spherical.theta + (Math.PI / 2)) + Math.PI) - (degToRad(23));

        compass = Math.round(compass * 10) / 10;

        if (compass != this.#oldAzimuth) {
            dispatch('setCameraHorizontalAngle', compass);
            this.#oldAzimuth = compass;
        }
    }

    updateCameraVerticalAngle() {
        const vector    = new THREE.Vector3();
        const center    = new THREE.Vector3();
        const spherical = new THREE.Spherical();

        vector.copy(this.getCameraDirection()(new THREE.Vector3(0, 0, 0)) ).sub( center );

        spherical.setFromVector3(vector);

        const rot   = spherical.phi;
        const value = 1 - ( 2 * (rot / Math.PI));
        const label = Math.round(90 * value);

        if (label != this.#oldElevation) {
            dispatch('setCameraVerticalAngle', label);
            this.#oldElevation = label;
        }
    }
}

export default Camera;
