import * as THREE from 'three';

import Controls from './Controls.js';
import FPVTouch from './FPVTouch.js';
import FPVMouse from './FPVMouse.js';

import {readState} from '../../lib/index.js';

import {getCamera} from '../Cameras/AppCamera.js';

import {satellitesCollection} from '../SceneElements/SatellitesCollection.js';

import {scene} from '../Scene.js';

import degToRad from '../../Utils/degToRad.js';
import {getDate} from '../../Utils/ApplicationDate.js';
import {rotateAroundObjectAxis, rotateAroundWorldAxis} from '../../Utils/rotateObject.js';

class SatCam extends Controls {

    previousSatCamGroupOrientation;
    fpvGroup = null;

    constructor(sceneDomElement) {
        super(sceneDomElement);

        if ('ontouchstart' in window) {
            this.controls = new FPVTouch(sceneDomElement);
        } else {
            this.controls = new FPVMouse(sceneDomElement);
        }

        const focusedObject = satellitesCollection[readState('focusedObject')];
        if (focusedObject) {
            this.setFocusedObject(null, focusedObject);
        }

        this.setCameraToSatellite();
    }

    setCameraToSatellite() {
        this.fpvGroup = new THREE.Group();
        this.fpvGroup.name = 'FPV';

        this.fpvGroup.add(getCamera().camera);

        scene.add(this.fpvGroup);
        //earthGroup.add(this.fpvGroup);

        getCamera().camera.position.x = 0;
        getCamera().camera.position.y = 0;
        getCamera().camera.position.z = 0;

        const satelliteComputedPosition = this.target.getCachedPositionForTime(getDate(), ['lat', 'lng']);

        this.previousSatCamGroupOrientation = {
            lat: satelliteComputedPosition.lat,
            lng: satelliteComputedPosition.lng,
        };

        rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 0, 1), satelliteComputedPosition.lat);
        rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 1, 0), satelliteComputedPosition.lng);
        //rotateAroundObjectAxis(this.fpvGroup, new THREE.Vector3(0, 0, 1), satelliteComputedPosition.lat - degToRad(90))
        //rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 1, 0), satelliteComputedPosition.lng)
    }

    update() {
        if (this.target) {

            const worldPosition = new THREE.Vector3();
            worldPosition.setFromMatrixPosition(this.target.getObject().matrixWorld );
            const position           = new THREE.Vector3(worldPosition.x, worldPosition.y, worldPosition.z);

            this.fpvGroup.position.x = position.x;
            this.fpvGroup.position.y = position.y;
            this.fpvGroup.position.z = position.z;

            const satelliteComputedPosition = this.target.getCachedPositionForTime(getDate(), ['lat', 'lng']);

            //this.fpvGroup.position.x = satelliteComputedPosition.x;
            //this.fpvGroup.position.y = satelliteComputedPosition.y;
            //this.fpvGroup.position.z = satelliteComputedPosition.z;

            const diffLat = satelliteComputedPosition.lat - this.previousSatCamGroupOrientation.lat;
            const diffLng = satelliteComputedPosition.lng - this.previousSatCamGroupOrientation.lng;

            rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 0, 1), diffLat);
            rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 1, 0), diffLng);

            this.previousSatCamGroupOrientation = {
                lat: satelliteComputedPosition.lat,
                lng: satelliteComputedPosition.lng,
            };
        }
    }

    stop() {
        this.controls.stop();
        this.fpvGroup.parent.remove(this.fpvGroup);
        super.stop();
    }

    setFocusedObject(oldObject, newObject) {
        this.setTarget(newObject);
    }

    getSatelliteScaleFactor() {
        return 0.2;
    }
}

export default SatCam;
