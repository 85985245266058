import * as THREE from 'three';

import galaxy from '../../../img/galaxy_4K.jpg';

import {scene} from '../Scene.js';

/////////////////
// Actual Objects
let universeObject;
let universeMaterial;

const initUniverse = function() {
    let diffuseUniverse        = new THREE.TextureLoader().load(galaxy);
    universeMaterial           = new THREE.MeshBasicMaterial();
    universeMaterial.minFilter = THREE.LinearFilter;
    universeMaterial.map       = diffuseUniverse;
    universeMaterial.side      = THREE.BackSide;

    const geometry = new THREE.SphereGeometry(300000, 32, 32);

    universeObject = new THREE.Mesh(geometry, universeMaterial);
    universeObject.rotateZ((63 * Math.PI) / 180);

    diffuseUniverse = null;

    scene.add(universeObject);
}

const killUniverse = function() {
    universeObject = null;
    universeMaterial = null;
}

export {initUniverse, killUniverse};
