let observer = null;

const options = {
    root: document.querySelector('#satList_list'),
    rootMargin: '0px',
    threshold: 0
}

const deleteObserver = function() {
    observer = null;
}

const checkIntersections = function(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.checkIntersect();
        }
    });
}

const initObserver = function() {
    observer = new IntersectionObserver(checkIntersections, options);
}

export {deleteObserver, initObserver, observer};
