import {Actions, Component, dispatch, html, render} from '../lib/index.js';

class SatTooltip extends Component{

    connectedCallback() {
        super.connectedCallback();

        //force style computation
        this.offsetWidth;
        this.classList.add('animation');
    }

    static get mappedProperties() {
        return [
            'objectsFromLauches'
        ]
    }

    setSatCam(e) {
        dispatch(Actions.setFocusedObject, this.satellite.id);
        dispatch(Actions.setViewMode, 'satCam');
    }

    selectSatellite() {
        if (this.satellite.focused) {
            dispatch(Actions.setFocusedObject, null);
        } else {
            dispatch(Actions.setFocusedObject, this.satellite.id);
        }
    }

    showSameLaunchObjects() {
        if (this.satellite.constellation) {
            if (this.getState('objectsFromLauches').includes(this.satellite.launchId)) {
                return html`<div class="action" onclick=${e => this.unselectObjectsFromLaunch()}>Hide same launch objects</div>`;
            } else {
                return html`<div class="action" onclick=${e => this.selectObjectsFromLaunch()}>Show same launch objects</div>`;
            }
        }
    }

    unselectObjectsFromLaunch() {
        dispatch(Actions.removeObjectFromLaunch, this.satellite.launchId);
    }

    selectObjectsFromLaunch() {
        dispatch(Actions.addObjectFromLaunch, this.satellite.launchId);
    }

    renderComponent() {
        render(this, html`
            <div id="tooltip_inner">
                <h4 onclick=${e => this.selectSatellite()}>${this.satellite.name}</h4>
                <div class="info first"><small>Altitude</small> <span>${this.satellite.height} km</span></div>
                <div class="info"><small>Velocity</small> <span>${this.satellite.velocity} km/s</span></div>
                <div class="action first" onclick=${e => this.setSatCam()}>Sat Cam</div>
                ${this.showSameLaunchObjects()}
            </div>
        `);
    }
}

customElements.define("sat-tooltip", SatTooltip);

export default SatTooltip;
