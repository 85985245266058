import {Actions, Component, dispatch, html, render} from '../lib/index.js';

import AutoCompleteTLEs from './AutoCompleteTLEs.js';

import parseTLEs from '../Utils/TLEParser.js';
import askGeolocation from '../Utils/geolocation.js';


class PopUpMenu extends Component{
    static get initialState() {
        return {
            'textareaValue': ''
        }
    }
    static get mappedProperties() {
        return [
            'coordinateSystem',
            'viewMode'
        ]
    }

    close() {
        this.dispatchEvent(new CustomEvent('close'));
    }

    setCoordinate(e) {
        dispatch(Actions.setCoordinateSystem, e.currentTarget.value);
    }

    saveTLE(e) {
        parseTLEs(this.getState('textareaValue'))
        .then(TLEs => dispatch(Actions.TLEAddActivated, TLEs));
        this.setState({'textareaValue': ''});

    }

    connectedCallback() {
        super.connectedCallback();

        //force style computation
        this.offsetWidth;
        this.classList.add('animation');
    }

    toggleFPV(e) {
        if (this.getState('viewMode') == 'fpv') {
            dispatch(Actions.setViewMode, 'orbital');
        } else {
            askGeolocation(() => dispatch(Actions.setViewMode, 'fpv'));
        }

        this.close();
    }

    renderComponent() {
        render(this, html`
            <div class="smallBorder top left"></div>
            <div class="smallBorder top right"></div>
            <div id="popUpMenu_inner">
                <div id="popUpMenu_close" onclick=${e => this.close(e)}>
                    <div></div>
                </div>
                <div class="section" id="coordinate">
                    <h2>Coordinate System</h2>
                    <label class="radio">
                        <input type="radio" class="coordinate" name="coordinate" value="eci" .checked="${this.getState('coordinateSystem') == 'eci'}" onclick=${e => this.setCoordinate(e)}/>
                        <div>ECI</div>
                    </label>


                    <label class="radio">
                        <input type="radio" class="coordinate" name="coordinate" value="ecef" .checked="${this.getState('coordinateSystem') == 'ecef'}"  onclick=${e => this.setCoordinate(e)} />
                        <div>ECEF</div>
                    </label>
                </div>
                <div class="section">
                    <h2>Search object</h2>
                    <autocomplete-tles></autocomplete-tles>
                </div>
                <div class="section">
                    <h2>Import TLE(s)</h2>
                    <textarea id="tleText" onkeyup=${e => {this.setState({'textareaValue': e.currentTarget.value})}} .value=${this.getState('textareaValue')} />
                    <button id="saveTle" onclick=${e => this.saveTLE(e)}>Project trajectories</button>
                </div>

                <div class="section section-fpv">
                    <h2>FPV</h2>
                    <label class="checkbox">
                        <input type="checkbox" id="fpv" class="fpv" name="fpv" .checked=${this.getState('viewMode') == 'fpv'} value="1" onchange=${e => this.toggleFPV(e)} />
                        <div>Enable FPV</div>
                    </label>
                </div>

            </div>
            <div id="about">
                <a is="glucose-a" data-route="about">about ARbit</a>
            </div>
            <div class="smallBorder bottom left"></div>
            <div class="smallBorder bottom right"></div>
        `);
    }
}

customElements.define("pop-up-menu", PopUpMenu);

export default PopUpMenu;
