import {subscribeToState, unsubscribeFromState} from '../../lib/index.js';

class Controls {

    controls;
    sceneDomElement;
    target;

    constructor(sceneDomElement) {
        this.sceneDomElement = sceneDomElement;

        this.bindedSetFocusedObject = this.setFocusedObject.bind(this);
        subscribeToState('focusedObject', this.bindedSetFocusedObject);
    }

    update() {
    }

    stop() {
        unsubscribeFromState('focusedObject', this.bindedSetFocusedObject);
    }

    setFocusedObject(oldObject, newObject) {
    }

    setTarget(object) {
        this.target = object
    }

    getSatelliteScaleFactor() {
        return 1;
    }
}

export default Controls;
