import {Actions, dispatch, readState, subscribeToState} from '../../lib/index.js';

import AppSatellite from './AppSatellite.js';

let satellitesCollection = {};

const addSatellitesFromTLEs = function(TLEs) {
    TLEs.map(tle => {
        const sat = new AppSatellite(tle);
        satellitesCollection[sat.id] = sat
    });
}
const addSatellitesFromConstellationTLEs = function(constelationName, TLEs) {
    TLEs.map(tle => {
        const sat = new AppSatellite(tle, constelationName);
        satellitesCollection[sat.id] = sat
    });
}
const removeSatellitesFromConstellations = function(constellations) {
    const ids = [];
    constellations.map(constellation => constellation.TLEs.map(tle => {
        ids.push(tle[3]);
    }));
    if (ids) {
        removeSatellitesFromIDs(ids);
    }
}
const removeSatellitesFromIDs = function(ids) {
    ids.map(id => {
        if (satellitesCollection[id]) {
            satellitesCollection[id].remove();

            delete satellitesCollection[id];
        }
    });
}

const updateFocused = function(oldName, newName) {
    if (oldName) {
        satellitesCollection[oldName].setFocused(false);
    }
    if (newName) {
        satellitesCollection[newName].setFocused(true);
    }
}
subscribeToState('focusedObject', updateFocused);


export {
    addSatellitesFromConstellationTLEs,
    addSatellitesFromTLEs,
    removeSatellitesFromConstellations,
    removeSatellitesFromIDs,
    satellitesCollection
};
