import {Component, html, render} from '../lib/index.js';

class AutoCompleteTLEsResult extends Component{

    showConstellation() {
        if (this.getAttribute('tle-constellation') === '1') {
            return html`<small>constellation</small>`;
        }
        return null;
    }

    renderComponent() {
        render(this, html`
            <div class="result">
                <p>${this.getAttribute('tle-name')}</p>
                ${this.showConstellation()}
            </div>
        `);
    }
}

customElements.define("autocomplete-tles-result", AutoCompleteTLEsResult);

export default AutoCompleteTLEsResult;
