import {
    Actions,
    dispatch,
    Router,
    readState,
    subscribeToState
} from '../lib/index.js';

import {defaultCoordinateSystem, defaultViewMode} from '../App/Settings.js';

import {importFromUrl} from './TLEImport.js';
import {constellations} from './Constellations.js';


const selectTLEsFromUrl = function(TLEs) {
    loadSatellitesFromNames(TLEs);
    loadSatellitesFromRegex(TLEs);

    loadSatellitesFromConstellations();

    //Subscribe after added sat from url to avoid duplication
    subscribeToState('TLE.activated', setQueryParametersFromSatellites);
    subscribeToState('TLE.constellations', setQueryParametersFromSatellites);
    subscribeToState('coordinateSystem', setQueryParametersFromSatellites);
    subscribeToState('objectsFromLauches', setQueryParametersFromSatellites);
}

const loadSatellitesFromNames = function(TLEs) {
    let names = readState('glucose.location.queryString.names');
    if (names) {
        names = decodeURIComponent(names).split(',');
        const sats  = TLEs.filter((sat) => names.indexOf(sat[0]) > -1);
        dispatch(Actions.TLEAddActivated, sats);
    }
}

const loadSatellitesFromRegex = function(TLEs) {
    const regexString = readState('glucose.location.queryString.regex');
    if (regexString) {
        const regex = regexString.split(",");

        var reg = new RegExp(regex.join('|'));
        const sats = TLEs
            .filter((sat) => sat[0].match(reg));


        dispatch(Actions.TLEAddActivated, sats);
    }
}

const loadSatellitesFromConstellations = function() {
    //
    const nameString = readState('glucose.location.queryString.constellations');

    if (nameString) {
        const sameLaunches = readState('glucose.location.queryString.objectsFromLauches');
        if (sameLaunches) {
            sameLaunches.split(',').forEach(launchId => dispatch(Actions.addObjectFromLaunch, launchId));
        }

        const names = nameString.split(',');

        names.forEach(name => {
            const lowName = name.toLowerCase()
            if (constellations[lowName] !== undefined) {
                importFromUrl(constellations[lowName].url, (TLEs => {
                    dispatch(Actions.TLEAddConstellation, {TLEs: TLEs, name: name});
                }));
            }
        });

    }
}

const setQueryParametersFromSatellites = function() {

    let params = {};

    const satellites = readState('TLE.activated');
    if (satellites.length > 200) {
        // Avoid "414 URI Too Long"
        return;
    }
    let names = [];
    satellites.map(sat => names.push(sat[0]));

    const TLEconstellations = readState('TLE.constellations');
    let constellations = [];
    TLEconstellations.map(constellation => constellations.push(constellation.name));

    const objectsFromLauches = readState('objectsFromLauches');

    if (names.length) {
        params.names          = names.join(',');
    }
    if (constellations.length) {
        params.constellations = constellations.join(',');
        if (objectsFromLauches) {
            params.objectsFromLauches = objectsFromLauches.join(',');
        }
    }

    if (readState('coordinateSystem') != defaultCoordinateSystem) {
        params.coordinateSystem = readState('coordinateSystem');
    }

    Router.navigate(Router.url('app', params), false);
}

export {selectTLEsFromUrl};
