import {Actions, Component, dispatch, html, render} from '../lib/index.js';

import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

import SatListEntry from './SatListEntry.js';
import SatListConstellation from './SatListConstellation.js';

import {initObserver, deleteObserver} from '../Utils/IntersectionObserver/SatListEntryObserver.js';

class SatList extends Component{
    static get initialState() {
        return {
            filterString: "",
            results: []
        }
    };
    static get mappedProperties() {
        return [
            'TLE.activated',
            'TLE.constellations',
            'focusedObject'
        ]
    }
    close(e) {
        this.dispatchEvent(new CustomEvent('close'));
    }

    connectedCallback() {
        initObserver();
        setTimeout(() => {
            super.connectedCallback();
        });

        this.offsetWidth; //force style computation
        this.classList.add('animation');
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        setTimeout(deleteObserver);
    }

    onFilterInput(e) {
        this.setState({'filterString': e.currentTarget.value});
    }

    filterList(list) {
        //let list = Object.values(satellitesCollection);
        if (this.getState('filterString')) {
            const reg = new RegExp(this.getState('filterString'), 'i');

            return list.filter(sat => sat[0].match(reg));
        }

        return list;
    }

    filterConstellation(constellations) {
        //let list = Object.values(satellitesCollection);
        const ret = [];

        if (constellations) {
            for (var i in constellations) {
                const filtered = this.filterList(constellations[i].TLEs);
                if (filtered && filtered.length) {
                    const tmp = constellations[i];
                    tmp.TLEs = filtered;
                    ret.push(tmp);
                }
            }
        }

        return ret;
    }

    renderComponent() {
        const filteredConstellations = this.filterConstellation(this.getState('TLE.constellations'));
        const filteredSatellites     = this.filterList(this.getState('TLE.activated'));
        let count = 0;
        filteredConstellations.forEach(constellation => count += constellation.TLEs.length);
        count    += filteredSatellites.length;

        const focusedObject = this.getState('focusedObject');

        render(this, html`
            <div class="smallBorder top left"></div>
            <div class="smallBorder top right"></div>
            <div id="satList_inner">
                <input type="text" id="satList_filter" autocomplete="off" onkeyup="${e => this.onFilterInput(e)}" placeholder="Filter" />
                <div id="satList_count">Filtered results count <span>${count}</span></div>
                <div id="satList_list">
                    ${filteredConstellations.map(constellation => html.for(this, constellation.name + (this.getState('filterString')))`
                        <sat-list-constellation .constellation=${constellation}></sat-list-constellation>
                        `
                    )}

                    ${filteredSatellites.map(TLE => html.for(this, TLE[3])`
                        <sat-list-entry
                            class="uiSat delete"
                            data-id=${TLE[3]}
                            data-name=${TLE[0]}
                            data-focused=${focusedObject == TLE[3] ? '1' : '0'}
                        ></sat-list-entry>
                    `)}
                </div>
            </div>
            <div class="smallBorder bottom left"></div>
            <div class="smallBorder bottom right"></div>
        `);
    }
}

customElements.define("sat-list", SatList);

export default SatList;
