import * as THREE from 'three';

import {geodeticToEcf} from 'satellite.js';

import Controls from './Controls.js';
import FPVTouch from './FPVTouch.js';
import FPVMouse from './FPVMouse.js';

import {Actions, dispatch, readState, subscribeToState, unsubscribeFromState} from '../../lib/index.js';

import {getCamera} from '../Cameras/AppCamera.js';

import {satellitesCollection} from '../SceneElements/SatellitesCollection.js';

import {earthGroup} from '../Scene.js';
import {earthRadius} from '../Settings.js';

import degToRad from '../../Utils/degToRad.js';
import {rotateAroundObjectAxis, rotateAroundWorldAxis} from '../../Utils/rotateObject.js';

class FPV extends Controls {

    fpvGroup = null;

    previousSatCamGroupOrientation;
    // Avoid lose of focus when hovering a sat
    #lockHover = false;

    constructor(sceneDomElement) {
        super(sceneDomElement);

        if ('ontouchstart' in window) {
            this.controls = new FPVTouch(sceneDomElement);
        } else {
            this.controls = new FPVMouse(sceneDomElement);
        }

        this.controls.listenEvent('rotation', (e) => {
            if (this.target && this.#lockHover === false) {
                // Lose focused on mouse / touch move the camera
                dispatch(Actions.setFocusedObject, null);
            }
        });

        getCamera().camera.rotation.order = "YXZ";

        this.setCameraToGeoloc();

        const focusedObject = readState('focusedObject');
        if (focusedObject) {
            this.setFocusedObject(null, focusedObject);
        }

        this.bindedSetHoverObject = this.setHoverObject.bind(this);
        subscribeToState('hoverObject', this.bindedSetHoverObject);
    }

    setCameraToGeoloc() {
        const geoloc   = readState('geolocation');

        const observer = {
            latitude: degToRad(geoloc.latitude),
            longitude: degToRad(geoloc.longitude),
            height: 0,
        };

        var observerEcf = geodeticToEcf(observer);

        observerEcf.x = (observerEcf.x / 6371) * earthRadius;
        observerEcf.y = (observerEcf.y / 6371) * earthRadius;
        observerEcf.z = (observerEcf.z / 6371) * earthRadius;

        this.fpvGroup            = new THREE.Group();
        this.fpvGroup.position.x = observerEcf.x;
        this.fpvGroup.position.y = observerEcf.z;
        this.fpvGroup.position.z = - observerEcf.y;

        rotateAroundObjectAxis(this.fpvGroup, new THREE.Vector3(0, 0, 1), observer.latitude - degToRad(90))
        rotateAroundWorldAxis(this.fpvGroup, new THREE.Vector3(0, 1, 0), observer.longitude)

        this.fpvGroup.add(getCamera().camera);

        earthGroup.add(this.fpvGroup);

        getCamera().camera.position.x = 0;
        getCamera().camera.position.y = ((((geoloc.altitude + 5) / 1000) / 6371) * earthRadius);
        getCamera().camera.position.z = 0;

    }

    update() {
        if (this.target) {
            let {azimuth, elevation, rangeSat} = this.target.getLookAngles();
            getCamera().camera.rotation.y      = -azimuth + (Math.PI / 2);
            getCamera().camera.rotation.x      = elevation;

            getCamera().updateCameraAngles();
        }
    }

    stop() {
        unsubscribeFromState('hoverObject', this.bindedSetHoverObject);
        this.controls.stop();
        this.fpvGroup.parent.remove(this.fpvGroup);
        super.stop();
    }

    setFocusedObject(oldObject, newObject) {
        let target = null;
        if (newObject) {
            target = satellitesCollection[newObject];
        }
        this.setTarget(target);
        setTimeout(() => {
            this.#lockHover = false;
        }, 200);
    }

    setHoverObject(oldObject, newObject) {
        if (newObject != null && this.target == null) {
            this.#lockHover = true;
            dispatch(Actions.setFocusedObject, newObject);
        }
    }

    setTarget(object) {
        if (object) {
            // Reset camera quaternion, only necessary for PointerLockControls
            getCamera().camera.quaternion.setFromRotationMatrix(this.fpvGroup.matrixWorld);
            getCamera().camera.rotation.order = "YXZ";
            getCamera().camera.rotation.y = 0;
            getCamera().camera.rotation.x = 0;
            getCamera().camera.rotation.z = 0;
        }
        this.target = object;
        this.update();
    }

    getSatelliteScaleFactor() {
        return 0.2;
    }
}

export default FPV;
