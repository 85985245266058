import * as THREE from 'three';
import {twoline2satrec, propagate, gstime, eciToGeodetic, eciToEcf, ecfToLookAngles} from 'satellite.js';

import {Actions, dispatch, readState, subscribeToState, unsubscribeFromState} from '../../lib/index.js';

import {getDate} from '../../Utils/ApplicationDate.js';
import degToRad from '../../Utils/degToRad.js';

import {controls} from '../Controls/AppControls.js';
import {getCamera} from '../Cameras/AppCamera.js';
import {earthRadius} from '../Settings.js';
import {satellites, orbits} from '../Scene.js';
import {renderWidth, renderHeight} from '../renderer.js';

import {addOrbitGeometry, removeOrbitGeometry} from './OrbitLines.js';
import {addSatelliteInstance, removeSatelliteInstance, updateSatelliteColor, updateSatelliteMatrix} from './SatelliteMeshes.js';

class AppSatellite {
    id;
    name;
    height;
    velocity;
    constellation;
    launchId;

    #satrec;
    #orbitDuration;

    #matrix;
    #position3;
    #rotationEuler;
    #scale3;
    #quaternion;

    #coordinateSystem;

    #showOrbit  = true;
    #showObject = true;

    #hovered    = false;
    #focused    = false;
    #scale;

    #bindedUpdateViewMode;

    #cachedPositions = {};

    constructor(tle, constellation) {
        this.name          = tle[0];
        this.id            = tle[3];
        this.launchId      = tle[1].split(' ')[2].replace(/\D/g, '');
        this.constellation = constellation ? constellation : null;
        this.#satrec       = twoline2satrec(tle[1], tle[2]);

        this.#orbitDuration = (2 * Math.PI) / this.#satrec.no;

        this.init();
    }

    init() {
        this.#matrix        = new THREE.Matrix4();
        this.#position3     = new THREE.Vector3();
        this.#rotationEuler = new THREE.Euler();
        this.#scale3        = new THREE.Vector3();
        this.#quaternion    = new THREE.Quaternion();

        this.#coordinateSystem = readState('coordinateSystem');

        this.#bindedUpdateViewMode = this.#setAutomaticScale.bind(this);
        subscribeToState('viewMode', this.#bindedUpdateViewMode);

        this.#cachedPositions = {
            current: {time: null, position: null},
            next   : {time: null},
            stepMilli: {}
        };

        if (this.#showObject) {
            this.#instanciateMesh();
        }

        this.#scale = undefined;

        if (this.#showOrbit) {
            setTimeout(() => {
                this.#drawOrbit();
            });
        }
    }

    updateForRender() {
        this.#updateMatrix();
    }

    getObject() {
        //Compute world position
        const objectWorld = new THREE.Matrix4();
        objectWorld.multiplyMatrices(
            satellites.matrixWorld,
            this.#matrix
        );

        return {
            matrixWorld: objectWorld
        };
    }

    getCachedPositionForTime(time, parameters) {
        let everythingInCache = true;

        parameters.forEach(parameter => {
            if (
                ! this.#cachedPositions.current.time
                || !this.#cachedPositions.current.time < time.getTime()
                || !this.#cachedPositions.next.time > time.getTime()
                || !this.#cachedPositions.current.position[parameter]
            ) {
                everythingInCache = false;
            }
        });

        this.#setCachedPositionForTime(time, parameters);

        const returnObject = {};
        parameters.forEach(parameter => {
            returnObject[parameter] = this.#interpolatePosition(time, parameter);
        });

        return this.#cachedPositions.current.position;
    }

    over(rawMouse) {
        this.#hovered = true;
    }

    out(rawMouse) {
        this.#hovered = false;
    }

    /*
     * return XY position of satellite on window
     */
    toScreenXY() {
        // In ECI satellites groups is rotated, retreiving world position
        const objectWorld = new THREE.Matrix4();
        objectWorld.multiplyMatrices(
            satellites.matrixWorld,
            this.#matrix
        );
        const worldPosition = new THREE.Vector3();
        worldPosition.setFromMatrixPosition(objectWorld);

        const projScreenMat = new THREE.Matrix4();
        projScreenMat.multiplyMatrices(
            getCamera().camera.projectionMatrix,
            getCamera().camera.matrixWorldInverse
        );
        projScreenMat.multiplyVector3(worldPosition);

        return {
            x: (worldPosition.x + 1)  * (renderWidth  / 2),
            y: (-worldPosition.y + 1) * (renderHeight / 2)
        };
    }

    setFocused(status) {
        this.#focused = status;
    }

    remove() {
        if (this.#focused) {
            dispatch(Actions.setFocusedObject, null);
        }
        if (this.#showObject) {
            removeSatelliteInstance(this.id);
        }
        if (this.#showOrbit) {
            removeOrbitGeometry(this.id);
        }
        unsubscribeFromState('viewMode', this.#bindedUpdateViewMode);
    }

    getLookAngles() {
        const positionAndVelocity = propagate(this.#satrec, getDate());

        const positionEci = positionAndVelocity.position;
        if (!positionEci) {
            //Error
            return false;
        }

        const gmst        = gstime(getDate());
        const positionEcf = eciToEcf(positionEci, gmst);

        const geoloc     = readState('geolocation');
        const observer = {
            latitude: degToRad(geoloc.latitude),
            longitude: degToRad(geoloc.longitude),
            height: 0,
        };

        const {azimuth, elevation, rangeSat} = ecfToLookAngles(observer, positionEcf);

        return {
            azimuth, elevation, rangeSat
        };
    }

    selectSameLaunchObject() {
        updateSatelliteColor(this.id, 'sameLaunch');
    }

    unselectSameLaunchObject() {
        updateSatelliteColor(this.id, 'default');
    }

    /////////////////
    //PRIVATE Methods
    /////////////////

    #instanciateMesh() {
        let color = 'default';
        if (readState('objectsFromLauches').includes(this.launchId)) {
            color = 'sameLaunch';
        }
        addSatelliteInstance(this.id, color);
    }

    #getScale() {
        if (this.#focused) {
            return this.#scale;
        }
        if (this.#hovered) {
            return this.#scale * 3;
        }

        return this.#scale;
    }

    /**
     * Update object matrix (position, rotation, scale)
     */
    #updateMatrix() {
        const positionParameters = [
            'height',
            'velocity'
        ];
        if (this.#coordinateSystem == "ecef") {
            positionParameters.push('x', 'y', 'z');
        } else {
            positionParameters.push('eciX', 'eciY', 'eciZ');
        }
        const computedPosition = this.getCachedPositionForTime(getDate(), positionParameters);

        if (computedPosition) {
            this.height   = computedPosition.height;
            this.velocity = computedPosition.velocity;
            if (this.#scale == undefined) {
                this.#setAutomaticScale();
            }
            const coord = this.#getCoordFromPosition(computedPosition);

            this.#position3.x = coord.x;
            this.#position3.y = coord.y;
            this.#position3.z = coord.z;

            this.#rotationEuler.x = 0;
            this.#rotationEuler.y = 0;
            this.#rotationEuler.z = 0;

            this.#scale3.x = this.#scale3.y = this.#scale3.z = this.#getScale();

            this.#quaternion.setFromEuler( this.#rotationEuler );

            this.#matrix.compose(this.#position3, this.#quaternion, this.#scale3);

            updateSatelliteMatrix(this.id, this.#matrix);
        }
    }

    #setAutomaticScale() {
        this.#scale  = (this.height * 0.00012);
        const limit  = controls.getSatelliteScaleFactor();
        this.#scale  = this.#scale > limit ? this.#scale : limit;
    }

    #drawOrbit() {
        //In minutes
        const start = getDate();
        const end   = getDate();
        if (this.#coordinateSystem == 'ecef') {
            let drawTimeLength = this.#orbitDuration < 200 ? 200 : this.#orbitDuration;
            const halfOrbitInMilli = (drawTimeLength  * 60 * 1000) / 2;
            start.setTime(start.getTime() - halfOrbitInMilli);
            end.setTime(end.getTime() + halfOrbitInMilli);
        } else {
            start.setTime(start.getTime() - ((this.#orbitDuration  * 60 * 1000) / 2));
            end.setTime(end.getTime() + ((this.#orbitDuration  * 60 * 1000) / 2));
        }

        const interval = 1 * 60 * 1000; // 1 minutes
        const now      = getDate();

        const geometry = new THREE.BufferGeometry();

        let positions = [];
        let colors    = [];

        const colorBefore = [255 / 255, 21 / 255, 58 / 255];
        const colorAfter  = [101 / 255, 252 / 255, 156 / 255]

        const positionParameters = [];
        if (this.#coordinateSystem == "ecef") {
            positionParameters.push('x', 'y', 'z');
        } else {
            positionParameters.push('eciX', 'eciY', 'eciZ');
        }
        while (start.getTime() <= (end.getTime() + interval)) {
            const position = this.#computePositionForTime(start, positionParameters);
            if (position) {
                const coord = this.#getCoordFromPosition(position);

                if (start < now) {
                    colors.push(...colorBefore, ...colorBefore);
                } else {
                    colors.push(...colorAfter, ...colorAfter);
                }
                positions.push(coord.x, coord.y, coord.z, coord.x, coord.y, coord.z);

                start.setTime(start.getTime() + interval);
            } else {
                return;
            }
        }

        //Remove first / last duplication for next orbit draw (lineSegments)
        //Orbits are merge into 1 lineSegment line
        positions.splice(0, 3);
        positions.splice(-3)
        colors.splice(0, 3);
        colors.splice(-3)

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        addOrbitGeometry(this.id, geometry);
    }

    #getCoordFromPosition(position){
        let x, y, z;
        if (this.#coordinateSystem == "ecef") {
            x = position.x;
            y = position.y;
            z = position.z;
        } else {
            x = position.eciX;
            y = position.eciY;
            z = position.eciZ;
        }

        return new THREE.Vector3(x,y,z);
    }

    #setCachedPositionForTime(time, parameters) {
        let nextInterval                      = 1 * 60 * 1000; //1 min;
        this.#cachedPositions.current.position = this.#computePositionForTime(time, parameters);
        this.#cachedPositions.current.time     = time.getTime();
        this.#cachedPositions.next.time        = (time.getTime() + nextInterval);

        let nextPosition = this.#computePositionForTime(new Date(time.getTime() + nextInterval), parameters);

        let compute = (paramName) => {
            return (nextPosition[paramName] - this.#cachedPositions.current.position[paramName]) / nextInterval;
        }

        const returnObject = {};

        parameters.forEach(parameter => {
            returnObject[parameter] = compute(parameter);
        })

        this.#cachedPositions.stepMilli = returnObject;
    }

    #computePositionForTime(time, parameters) {
        const positionAndVelocity = propagate(this.#satrec, time);

        const positionEci = positionAndVelocity.position;
        if (!positionEci) {
            //Error
            return false;
        }

        const gmst        = gstime(time);
        const positionGd  = eciToGeodetic(positionEci, gmst)

        const returnObject = {};

        if (parameters.indexOf('height') != -1) {
            returnObject['height'] = Math.round(positionGd.height);
        }
        if (parameters.indexOf('velocity') != -1) {
            const velocityEci = positionAndVelocity.velocity;
            returnObject['velocity'] = Math.round((Math.sqrt(
                velocityEci.x * velocityEci.x +
                velocityEci.y * velocityEci.y +
                velocityEci.z * velocityEci.z
            ) * 100)) / 100;
        }

        if (parameters.indexOf('lat') != -1) {
            returnObject['lat'] = positionGd.latitude;
            returnObject['lng'] = positionGd.longitude;
        }
        if (parameters.indexOf('eciX') != -1) {
            returnObject['eciX'] = (positionEci.x / 6371)  * earthRadius;
            returnObject['eciY'] = (positionEci.z / 6371)  * earthRadius;
            returnObject['eciZ'] = (-positionEci.y / 6371) * earthRadius;
        }

        if (parameters.indexOf('x') != -1) {
            const positionEcf  = eciToEcf(positionEci, gmst);
            returnObject['x'] = (positionEcf.x / 6371)  * earthRadius;
            returnObject['y'] = (positionEcf.z / 6371)  * earthRadius;
            returnObject['z'] = -(positionEcf.y / 6371) * earthRadius;
        }

        return returnObject;
    }


    #interpolatePosition(time, parameter) {
        const currentMilli = time.getTime() - this.#cachedPositions.current.time;

        let compute = (paramName) => {
            return this.#cachedPositions.current.position[paramName] + ( this.#cachedPositions.stepMilli[paramName]   * currentMilli );
        }

        return compute(parameter);
    }
}

export default AppSatellite;
