import * as THREE from 'three';

const getUniformsEarth = function(atmosphere, earthRotation, inclination, diffuse, diffuseNight, specular) {
    return {
        v3LightPosition: {
            type: "v3",
            value: new THREE.Vector3(0, 0, 1)
        },
        v3LightPositionSpec: {
            type: "v3",
            value: new THREE.Vector3(0, 0, 1)
        },
        rotationY:  { type: "f", value: earthRotation },
        inclination:  { type: "f", value: inclination },
        v3InvWavelength: {
            type: "v3",
            value: new THREE.Vector3(1 / Math.pow(atmosphere.wavelength[0], 4), 1 / Math.pow(atmosphere.wavelength[1], 4), 1 / Math.pow(atmosphere.wavelength[2], 4))
        },
        fCameraHeight2: {
            type: "f",
            value: 0
        },
        fInnerRadius: {
            type: "f",
            value: atmosphere.innerRadius
        },
        fOuterRadius: {
            type: "f",
            value: atmosphere.outerRadius
        },
        fOuterRadius2: {
            type: "f",
            value: atmosphere.outerRadius * atmosphere.outerRadius
        },
        fKrESun: {
            type: "f",
            value: atmosphere.Kr * atmosphere.ESun
        },
        fKmESun: {
            type: "f",
            value: atmosphere.Km * atmosphere.ESun
        },
        fKr4PI: {
            type: "f",
            value: atmosphere.Kr * 4.0 * Math.PI
        },
        fKm4PI: {
            type: "f",
            value: atmosphere.Km * 4.0 * Math.PI
        },
        fScale: {
            type: "f",
            value: 1 / (atmosphere.outerRadius - atmosphere.innerRadius)
        },
        fScaleDepth: {
            type: "f",
            value: atmosphere.scaleDepth
        },
        fScaleOverScaleDepth: {
            type: "f",
            value: 1 / (atmosphere.outerRadius - atmosphere.innerRadius) / atmosphere.scaleDepth
        },
        tDiffuse: {
            type: "t",
            value: diffuse
        },
        tDiffuseNight: {
            type: "t",
            value: diffuseNight
        },
        fNightScale: {
            type: "f",
            value: 1
        },
        tSpecular: {
            type: "t",
            value: specular
        }
    };
}

export default getUniformsEarth;
