import {gstime} from "satellite.js"
import * as THREE from 'three';

import {readState, subscribeToState} from '../lib/index.js';

import {getEarthRotation} from './SceneElements/Earth.js';
import {satellitesCollection} from './SceneElements/SatellitesCollection.js';

import getEarthSeasonalInclination from '../Utils/getEarthSeasonalInclination.js';
import {getDate} from '../Utils/ApplicationDate.js';

let scene;
let satellites;
let orbits;
let earthGroup;
let moonGroup;
let fpvGroup;

const initScene = function() {
    scene      = new THREE.Scene();
    satellites = new THREE.Group();
    orbits     = new THREE.Group();
    earthGroup = new THREE.Group();
    moonGroup  = new THREE.Group();
    fpvGroup   = new THREE.Group();

    scene.add(satellites);
    scene.add(orbits);
    scene.add(earthGroup);
    earthGroup.add(moonGroup);
    earthGroup.add(fpvGroup);

    //app.tools.xyz.init();
    rotateWorld();
}

const resetScene = function() {
    const sats = Object.values(satellitesCollection);
    sats.map(sat => sat.remove());
    //
    scene.remove(satellites);
    scene.remove(orbits);
    //
    satellites = new THREE.Group();
    orbits     = new THREE.Group();
    scene.add(satellites);
    scene.add(orbits);

    //app.tools.xyz.init();
    sats.forEach(sat => sat.init());

    rotateWorld();
}
subscribeToState('coordinateSystem', resetScene);


const rotateWorld = function() {
    if (readState('coordinateSystem') == 'eci') {

        var greenwitchSideralTime  = gstime(getDate()) - getEarthRotation();

        orbits.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), - greenwitchSideralTime);
        satellites.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), - greenwitchSideralTime);

        orbits.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), getEarthSeasonalInclination() * (Math.PI / 180));
        satellites.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), getEarthSeasonalInclination() * (Math.PI / 180));
    }
}

const killScene = function() {
    //clearThree(scene);
    scene = null;
    satellites = null
    orbits = null;
    earthGroup = null
    moonGroup = null;
    fpvGroup = null;
}

const clearThree = function(obj){
    while(obj.children.length > 0){
        clearThree(obj.children[0])
        obj.remove(obj.children[0]);
    }
    if (obj.geometry){
        obj.geometry.dispose();
    }

    if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach(prop => {
            if(!obj.material[prop]) {
                return
            }
            if(typeof obj.material[prop].dispose === 'function') {
              obj.material[prop].dispose();
            }
        })
        obj.material.dispose()
    }
}




export {
    earthGroup,
    moonGroup,
    fpvGroup,
    orbits,
    satellites,
    scene,

    initScene,
    killScene
};
