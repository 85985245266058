import Stats from './dep/stats.module.js';

let stats;

const initDebug = function(domElement) {
    // stats
    stats = new Stats();
    domElement.appendChild(stats.dom);

    stats.domElement.style.top    = 'auto';
    stats.domElement.style.bottom = '0px';
}

const updateDebugForRender = function() {
    stats.update();
}

export {initDebug, updateDebugForRender};
