 const parseTLEs = function(text) {
    return new Promise((resolve, reject) => {
        let TLE = text.split("\n");
        TLE     = TLE.map((str) => str.trim());

        let tleList = [];

        let tle = [];
        let start = true;

        for (let i in TLE) {
            let line = TLE[i];
            if (line == '') {continue;}

            if (start) {
                tle    = [];
                tle[0] = line.trim();
                start  = false;
            } else {
                if (line.match(/^1/)) {
                    tle[1] = line.trim();
                    tle[3] = tle[1].split(' ')[1];
                }
                if (line.match(/^2/)) {
                    tle[2] = line.trim();
                    start  = true;
                    tleList.push(tle);
                }
            }

        }

        resolve(tleList);
    });
}

export default parseTLEs;
