import {getCamera} from '../Cameras/AppCamera.js';

class FPVTouch {
    #prevX     = null;
    #prevY     = null;
    #prevPinch = null;
    #eventsCallback;

    #cameraFocalFactor = null;

    #zoomSpeed = -1;

    #action  = null; // Pan or zoom (on or 2 fingers

    constructor(sceneDomElement) {
        this.#eventsCallback = {};
        this.sceneDomElement = sceneDomElement;

        getCamera().camera.rotation.order = "YXZ";

        this.bindedStartMove = this.startMove.bind(this);
        this.bindedEndMove   = this.endMove.bind(this);
        this.bindedMove      = this.move.bind(this);

        this.sceneDomElement.addEventListener('touchstart', this.bindedStartMove, false);
        this.sceneDomElement.addEventListener('touchend', this.bindedEndMove, false);
        this.sceneDomElement.addEventListener('touchmove', this.bindedMove, false);
    }

    startMove(e) {
        this.#prevX = e.touches[0].pageX;
        this.#prevY = e.touches[0].pageY;
    }

    endMove(e) {
        this.#prevX = null;
        this.#prevY = null;


        this.#prevPinch = null;
        if (e.touches.length == 0) {
            this.#action = null;
        }
    }

    move(e){
        if (e.touches.length == 1 && this.#action != 'zoom') {
            this.rotateCamera(e);
            this.#action = 'pan';
        }

        if (e.touches.length == 2) {
            this.#action = 'zoom';
            this.changeFOV(e);
        }
        e.preventDefault();
    }

    listenEvent(type, cb) {
        this.#eventsCallback[type] = cb;
    }

    rotateCamera(evt) {
        if (this.#eventsCallback['rotation']) {
            this.#eventsCallback['rotation'].call();
        }

        let movementX = this.#prevX - evt.touches[0].pageX;
        let movementY = this.#prevY - evt.touches[0].pageY;

        this.#prevX = evt.touches[0].pageX;
        this.#prevY = evt.touches[0].pageY;

        getCamera().camera.rotation.x -= movementY / this.proportionalizeDragOnFocal();
        getCamera().camera.rotation.y -= movementX / this.proportionalizeDragOnFocal();

        getCamera().updateCameraAngles();
    }

    proportionalizeDragOnFocal() {
        this.#cameraFocalFactor;
        const {min, max} = getCamera().getFocalFactorLimit();

        const focalScale = this.#cameraFocalFactor - min / (max - min);

        return 400 + (150 * focalScale);
    }

    changeFOV(e) {
        let dx = e.touches[0].pageX - e.touches[1].pageX;
        let dy = e.touches[0].pageY - e.touches[1].pageY;

        let pinch = Math.sqrt( dx * dx + dy * dy );

        if (this.#prevPinch == null) {
            this.#prevPinch = pinch;
            return;
        }

        var deltaFactor = Math.pow( pinch / this.#prevPinch, this.#zoomSpeed );

        let factor = getCamera().getFocalFactor();
        if (
            (deltaFactor > 1)
            ||
            (deltaFactor < 1)
        ) {
            factor /= deltaFactor;
        }

        this.#cameraFocalFactor = getCamera().setFocalFactor(factor);

        this.#prevPinch = pinch;
    }

    stop() {
        this.sceneDomElement.removeEventListener('touchstart', this.bindedStartMove, false);
        this.sceneDomElement.removeEventListener('touchend', this.bindedEndMove, false);
        this.sceneDomElement.removeEventListener('touchmove', this.bindedMove, false);
    }
}

export default FPVTouch;
