import {Actions, classes, Component, dispatch, readState, html, render} from '../lib/index.js';

//import {satellitesCollection} from '../App/SceneElements/SatellitesCollection.js';

//import {observer} from '../Utils/IntersectionObserver/SatListEntryObserver.js';

class SatListConstellation extends Component{

    static get initialState() {
        return {
            opened: false
        }
    };

    static get mappedProperties() {
        return [
            'focusedObject',
            'TLE.constellations'
        ]
    }

    static get observedAttributes() {return ['data-focused']; }

    toggle(e) {
        this.setState({'opened': !this.getState('opened')});
    }

    remove() {
        const constellation = this.getState('TLE.constellations').filter(constellation => constellation.name === this.constellation.name);
        if (!constellation) {
            throw new ReferenceError(`Unable to find constellation id ${this.constellation.name}`);
        }
        dispatch(Actions.TLERemoveConstellation, constellation);
    }

    renderSatellites() {
        if (this.getState('opened')) {
            const focusedObject = this.getState('focusedObject');
            return html`<div class="constellation-satellites">
                ${this.constellation.TLEs.map(TLE => html.for(this, TLE[3])`
                    <sat-list-entry
                        class="uiSat"
                        data-id=${TLE[3]}
                        data-name=${TLE[0]}
                        data-focused=${focusedObject == TLE[3] ? '1' : '0'}
                    ></sat-list-entry>
                `)}
            </div>`
        }

        return null;
    }

    renderComponent() {

        render(this, html`
            <div class="constellation-infos" onclick="${e => this.toggle(e)}">
                <p>${this.constellation.name} <small>constellation</small></p>
                <div class="count">${this.constellation.TLEs.length}</div>
            </div>
            <div class="close" onmouseup=${e => this.remove(e)}><div class="inner"></div></div>
            ${this.renderSatellites()}
        `);
    }
}

customElements.define("sat-list-constellation", SatListConstellation);

export default SatListConstellation;
