import Camera from './Camera.js';
import {earthRadius} from '../Settings.js';

class FPVCamera extends Camera {

    static mode = 'fpv';

    constructor(params) {
        super(params);

        this.camera.position.set(0, 0, 0);
    }

    getDistanceLimit() {
        return {
            near: 0.00001,
            far : 10000
        }
    }

    getFocalFactorLimit() {
        return {
            min: 0.4,
            max: 3
        };
    }
}


export default FPVCamera;
