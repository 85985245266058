import {setInitialGlobalState} from './lib/index.js';

import {defaultCoordinateSystem, defaultViewMode} from './App/Settings.js';

setInitialGlobalState({
    timeProjection: {
        value: 0
    },
    TLE: {
        available: false,
        activated: [],
        constellations: []
    },
    coordinateSystem: defaultCoordinateSystem,
    viewMode: defaultViewMode,
    focusedObject: null,
    hoverObject: null,
    focalFactor: 1,
    cameraAngles: {
        horizontal: 0,
        vertical: 0
    },
    objectsFromLauches: [],
    geolocation: null
});

export default null;
